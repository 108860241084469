<template>
  <a-space direction="vertical" size="large">
  <span>{{ text }}</span>
  <a-upload
      name="file"
      :multiple="false"
      list-type="picture-card"
      class="file-uploader"
      :show-upload-list="false"
      :action="uploadUrl"
      :before-upload="beforeUpload"
      @change="handleChange"
  >
    <img v-if="imageUrl" :src="imageUrl" style="width: 10rem; height: 10rem;"/>
    <div v-else>
      <LoadingOutlined v-if="loading"/><PlusOutlined v-else/>
      <div class="ant-upload-text">
        Upload
      </div>
    </div>
  </a-upload>
  </a-space>
</template>

<script>
import { message } from 'ant-design-vue'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons-vue'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'SingleFileUpload',

  components: {
    LoadingOutlined,
    PlusOutlined
  },

  props: {
    bizId: {
      type: Number,
      default: 0
    },

    text: {
      type: String,
      default: 'Click to upload file'
    },

    url: {
      type: String,
      default: ''
    }
  },

  emits: ['fileUploaded'],

  data () {
    return {
      loading: false,
      imageUrl: this.url,
      fileName: '',
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'user/upload'
    }
  },

  methods: {
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        this.fileName = info.file.response?.name

        if (!this.fileName || this.fileName.length === 0) {
          message.error(`Failed to upload ${info.file.name} due to unknown problem, please try later`)
        } else {
          // bizId用于区别不同的业务上传的图片
          this.$emit('fileUploaded', this.fileName, this.bizId)
          // Get this url from response in real world.
          getBase64(info.file.originFileObj, imageUrl => {
            this.imageUrl = imageUrl
            this.loading = false
          })
        }
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
        return
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isLt2M) {
        message.error('Image must smaller than 10MB!')
        return
      }
      return isJpgOrPng && isLt2M
    }
  }
}
</script>

<style scoped lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
