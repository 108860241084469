<template>
    <a-calendar v-model:value="value" @change="dateChange">
        <template #dateCellRender="{ current: value }">
            <a-popover title="Attendance">
                <template #content>
<!--                    {{ getDateAttendance(value)[0].content}}-->
                    {{ getDateAttendance(value)[0]?.content }}
                </template>
                <ul class="events">
                    <li v-for="item in getDateAttendance(value)" :key="item.content">
                        <a-badge :color="item.color" :text="item.content" />
                    </li>
                </ul>
            </a-popover>
        </template>
    </a-calendar>
</template>

<script>
import { ref } from 'vue'
import {
  getPersonalMonthlyAttendance
} from '../../api/staff'
import { useRoute } from 'vue-router'
import { AttendanceStatus } from '../../api/codes'

import { showTime } from '../../utils/util'

export default {
  name: 'PersonalAttendance',

  setup () {
    const route = useRoute()
    const staffId = route.query.staffId || undefined
    const value = ref()
    return {
      value,
      staffId
    }
  },

  mounted () {
    this.loadPersonalMonthlyData()
  },

  data () {
    return {
      monthlyData: {},
      monthlyExceptions: {},
      currentMonth: new Date().getMonth() + 1,
      currentYear: new Date().getFullYear()
    }
  },

  methods: {
    dateChange (m) {
      const dt = m.toDate()
      const year = dt.getFullYear()
      const month = dt.getMonth() + 1
      const now = new Date()
      if (month > (now.getMonth() + 1)) {
        return
      }
      if (month !== this.currentMonth || year !== this.currentYear) {
        this.currentYear = year
        this.currentMonth = month
        this.loadPersonalMonthlyData()
      }
    },

    loadPersonalMonthlyData () {
      this.$loading.show('Loading staff attendance data...')
      getPersonalMonthlyAttendance({
        year: this.currentYear,
        month: this.currentMonth,
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.monthlyData = res.data.monthly
        } else {
          console.log('error')
        }
        this.$loading.hide()
      }).catch(err => {
        console.log(err)
        this.$loading.hide()
      })
    },

    getDateAttendance (date) {
      const day = date.date()
      const month = date.month() + 1

      if (month !== this.currentMonth) {
        return []
      }

      const items = this.monthlyData[day]

      if (!items || items.length === 0) {
        return []
      }

      const data = []

      items.forEach(item => {
        if (item.type === AttendanceStatus.PRESENT) {
          data.push({
            color: 'green',
            content: this.showTime(item.time)
          })
        } else if (item.type === AttendanceStatus.ABSENT) {
          data.push({
            color: 'red',
            content: 'Absent'
          })
        } else if (item.type === AttendanceStatus.LEAVE) {
          data.push({
            color: 'yellow',
            content: `Leave ${item.desc}`
          })
        }
      })

      return data
    },

    showTime
  }
}
</script>

<style scoped lang="scss">

.title {
  font-weight: bold;
}

.desc {
  margin-left: 0.1rem;
  color: #777;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
.notes-month {
  text-align: center;
  font-size: 28px;
}
.notes-month section {
  font-size: 28px;
}

</style>
