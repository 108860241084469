<template>
    <a-button type="primary" @click="newRecord">New Payroll Record</a-button>
    <a-table :columns="columns" :data-source="payrolls" :pagination="pager" :loading="loading" rowKey="id" size="small"
             style="margin-top: 1rem;" @change="onChange">
        <template v-for="col in ['amount', 'comment', 'currency']" :key="col" #[col]="{ text, record }">
            <div>
                <a-input
                        v-if="editableData[record.id]"
                        v-model:value="editableData[record.id][col]"
                        style="margin: -5px 0"
                />
                <template v-else>
                    {{ text }}
                </template>
            </div>
        </template>

        <template v-for="col in ['create_at', 'update_at']" :key="col" #[col]="{ text }">
            {{ showDate(text, false) }}
        </template>

        <template #operation="{ record }">
            <div class="editable-row-operations">
              <span v-if="editableData[record.id]">
                <a-space>
                  <a @click="save(record.id)">Save</a>
                  <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
                </a-space>
              </span>
                <span v-else>
                <a-button @click="edit(record.id)">Edit</a-button>
              </span>
            </div>
        </template>
    </a-table>
</template>

<script>

import { getStaffPayroll, updateStaffPayroll } from '../../api/staff'
import { message } from 'ant-design-vue'
import { showDate } from '../../utils/util'
import { cloneDeep } from 'lodash-es'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Payroll',
    dataIndex: 'amount',
    width: '8%',
    slots: { customRender: 'amount' },
    ellipsis: true
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    width: '7%',
    slots: { customRender: 'currency' },
    ellipsis: true
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    width: '20%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Payroll Date',
    dataIndex: 'create_at',
    width: '10%',
    slots: { customRender: 'create_at' }
  },
  {
    title: 'Updater',
    dataIndex: 'updater',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Update At',
    dataIndex: 'update_at',
    width: '10%',
    slots: { customRender: 'update_at' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '20%'
  }
]

export default {
  name: 'StaffPayroll',

  props: {
    staffId: Number
  },

  setup () {
    const store = useStore()

    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  watch: {
    staffId (newVal, oldVal) {
      if (typeof newVal !== 'undefined') {
        this.loadStaffPayrolls()
      }
    }
  },

  data () {
    return {
      columns,
      payrolls: [],
      editableData: {},
      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },
      loading: false
    }
  },

  methods: {
    loadStaffPayrolls () {
      this.loading = true
      const params = {
        staff_id: this.staffId,
        pager: {
          offset: (this.pager.current - 1) * this.pager.pageSize,
          limit: this.pager.pageSize
        }
      }
      getStaffPayroll(params).then(res => {
        if (res.code === 0) {
          this.payrolls = res.data.payrolls
          this.pager.total = res.data.total
        } else {
          message.error('Failed to load staff payroll info, please retry')
        }
        this.loading = false
      }).catch(err => {
        console.log(err)
        message.error('Failed to load staff payroll info, please retry')
        this.loading = false
      })
    },

    edit (key) {
      this.editableData[key] = cloneDeep(this.payrolls.filter(item => item.id === key)[0])
    },

    cancel (key) {
      if (key === -1) {
        this.payrolls.splice(0, 1)
      }
      delete this.editableData[key]
    },

    save (key) {
      let params = {
        id: key,
        amount: this.editableData[key].amount,
        currency: this.editableData[key].currency,
        comment: this.editableData[key].comment
      }

      if (key === -1) {
        params = {
          creator_id: this.userId,
          staff_id: this.staffId,
          ...params
        }
      } else {
        params = {
          updater_id: this.userId,
          ...params
        }
      }

      if (!params.amount) {
        message.error('Please input payroll amount')
        return
      }

      if (!params.comment) {
        message.error('Please input comment')
        return
      }

      if (!params.currency) {
        message.error('Please input currency')
        return
      }

      updateStaffPayroll(params).then(res => {
        if (res.code === 0) {
          this.resetPager()
          this.loadStaffPayrolls()
          delete this.editableData[key]
        } else {
          message.error('Failed to update staff payroll info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    newRecord () {
      this.payrolls.splice(0, 0, {
        id: -1
      })
      this.edit(-1)
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination
      this.loadStaffPayrolls()
    },

    resetPager () {
      this.pager = {
        pageSize: 10,
        total: 0,
        current: 1
      }
    },

    showDate
  }
}
</script>

<style scoped>

</style>
