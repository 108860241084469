<template>
    <a-space size="large" style="margin: 1rem 0;">
    <a-button type="primary" @click="create">New Leave</a-button>
  </a-space>
  <a-table :loading="loading" :columns="columns" :data-source="leaves" size="small">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>
    <template #leave_type="{ record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].leave_type" placeholder="Leave type"
                showSearch style="width: 100%;">
        <a-select-option v-for="leave_type in leave_types" v-bind:key="leave_type.id" :value="leave_type.code_name">
          {{ leave_type.code_name }}
        </a-select-option>
      </a-select>
      <template v-else>
        {{ record.leave_type }}
      </template>
    </template>
    <template v-for="col in ['reason', 'comment']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #start="{ record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].start"
            :allow-clear="false"
            style="margin: -5px 0; width: 100%"
        />
        <template v-else>
          {{
            showDate(record.start, false)
          }}
        </template>
      </div>
    </template>
    <template #end="{ record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].end"
            :allow-clear="false"
            style="margin: -5px 0; width: 100%"
        />
        <template v-else>
          {{
            showDate(record.end, false)
          }}
        </template>
      </div>
    </template>
    <template v-for="col in ['create_at']" :key="col" #[col]="{ text }">
      {{ showDate(text, false) }}
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
            <span v-if="editableData[record.id]">
              <a @click="save(record.id)">Save</a>
              <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
            </span>
        <span v-else>
              <a-button @click="edit(record.id)">Edit</a-button>
            </span>
      </div>
    </template>
  </a-table>

  <a-modal
      title="New Leave"
      centered
      v-model:visible="visible"
      @ok="newSubmit"
      @cancel="newReset"
      okText="Save"
  >
    <a-form :rules="rules" :model="newItem" ref="newForm">
      <a-form-item name="leave_type" hasFeedback required>
        <a-select v-model:value="newItem.leave_type" placeholder="Leave type" showSearch style="width: 100%;">
          <a-select-option v-for="leave_type in leave_types" v-bind:key="leave_type.id" :value="leave_type.id">
            {{ leave_type.code_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="reason" hasFeedback required>
          <a-input
              placeholder="Reason"
              v-model:value="newItem.reason"
          />
      </a-form-item>

      <a-form-item name="range" required>
        <a-range-picker
            :placeholder="['Start Date', 'End Date']"
            format="YYYY-MM-DD"
            v-model:value="newItem.range"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="comment" hasFeedback>
        <a-textarea
            placeholder="Comments"
            v-model:value="newItem.comment"
            allowClear
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { cloneDeep } from 'lodash-es'
import { updateStaffLeaveInfo, createStaffLeaveInfo, getStaffLeaveInfo } from '../../api/staff'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Type',
    dataIndex: 'leave_type',
    width: '15%',
    slots: { customRender: 'leave_type' },
    ellipsis: true
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    width: '15%',
    slots: { customRender: 'reason' },
    ellipsis: true
  },
  {
    title: 'Comments',
    dataIndex: 'comment',
    width: '15%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    ellipsis: true
  },
  {
    title: 'End',
    dataIndex: 'end',
    width: '10%',
    slots: { customRender: 'end' },
    ellipsis: true
  },
  {
    title: 'Approver',
    dataIndex: 'approver',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Recorded',
    dataIndex: 'create_at',
    width: '10%',
    slots: { customRender: 'create_at' }
  },
  {
    title: 'Op',
    dataIndex: 'operation',
    width: '10%',
    slots: { customRender: 'operation' }
  }
]

export default {
  name: 'StaffLeave',

  props: {
    leave_types: {
      type: Array,
      default: () => []
    }
  },

  setup () {
    const route = useRoute()
    const store = useStore()
    const staffId = route.query.staffId || undefined
    const user = computed(() => store.state.auth.user)

    return {
      staffId,
      user
    }
  },

  created () {
    this.loadLeaveInfo()
  },

  data () {
    return {
      columns,

      leaves: [],

      editableData: [],

      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },

      dateFormat: 'YYYY/MM/DD',

      newItem: {
        range: [],
        comment: undefined,
        reason: undefined,
        leave_type: undefined
      },

      rules: {
        leave_type: [
          {
            required: true,
            type: 'number'
          }
        ],
        range: [
          {
            required: true,
            type: 'array'
          }
        ]
      },

      loading: false,
      visible: false
    }
  },

  methods: {
    loadLeaveInfo () {
      this.loading = true
      getStaffLeaveInfo({
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.leaves = res.data.leaves
        } else {
          message.error('Failed to load staff leave info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },

    create () {
      this.visible = true
    },

    edit (key) {
      this.editableData[key] = cloneDeep(this.leaves.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      const params = {
        id: key,
        leave_type_id: this.leave_types.filter(item => item.code_name === this.editableData[key].leave_type)[0].id,
        reason: this.editableData[key].reason,
        comment: this.editableData[key].comment,
        start: this.editableData[key].start,
        end: this.editableData[key].end,
        modifier_id: this.user.id
      }

      updateStaffLeaveInfo(params).then(res => {
        if (res.code === 0) {
          const changed = this.leaves.filter(item => key === item.id)[0]
          Object.assign(changed, this.editableData[key])
          delete this.editableData[key]
          message.success('Successfully update staff leave info')
        } else {
          message.error('Failed to update staff leave info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      })
    },

    newSubmit () {
      console.log(this.$refs.newForm.values)
      this.$refs.newForm.validate().then(() => {
        createStaffLeaveInfo({
          staff_id: this.staffId,
          leave_type_id: this.newItem.leave_type,
          reason: this.newItem.reason,
          comment: this.newItem.comment,
          start: this.newItem.range[0],
          end: this.newItem.range[1],
          creator_id: this.user.id,
          approver_id: this.user.id
        }).then(res => {
          if (res.code === 0) {
            this.visible = false
            message.success('Successfully created new leave.')
            // this.loadLeaveInfo()
            setTimeout(() => {
              this.loadLeaveInfo()
            }, 500)
          } else {
            message.success('Failed to create new leave, please try again later')
          }
        }).catch(err => {
          message.error('Probably network broken, please try again later')
          console.log(err)
        })
      })
    },

    newReset () {
      this.visible = false
      this.$refs.newForm.resetFields()
    },

    showDate
  }
}
</script>

<style scoped>

</style>
