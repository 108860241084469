<template>
  <a-space size="large" style="margin-bottom: 1rem;">
    <span>Total: {{ getTotalAmount() }} </span>
    <span>Installments: {{ loanEditor ? loanEditor.length : 0 }}</span>
  </a-space>
  <a-table rowKey="index" :show-header="false" :data-source="loanEditor" :columns="columns" :pagination="false"  size="small">
    <template #index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-for="col in ['amount']" :key="col" #[col]="{ text, index }">
      <div>
        <a-input-number
            placeholder="Amount"
            v-if="editableData[index]"
            v-model:value="editableData[index][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template v-for="col in ['due']" :key="col" #[col]="{ index, record }">
      <div>
        <a-date-picker
            placeholder="Repay Date"
            v-if="editableData[index]"
            v-model:value="editableData[index][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ showDate(record.due, false) }}
        </template>
      </div>
    </template>
    <template #status="{ index, record }">
      <a-select v-if="editableData[index]" v-model:value="editableData[index].status" placeholder="Repaid status"
                style="width: 100%;">
        <a-select-option :value="0">
          Not Repaid Yet
        </a-select-option>
        <a-select-option :value="1">
          Already Repaid
        </a-select-option>
      </a-select>
      <template v-else>
        {{ record.leave_type }}
      </template>
    </template>

    <template #operation="{ index }">
      <a @click="del(index)">Delete</a>
    </template>
  </a-table>
  <a-space size="large" style="margin-top: 1rem;">
    <a-button @click="add" style="margin-bottom: 8px">Add Installment</a-button>
    <a-button @click="save" style="margin-bottom: 8px" type="primary">Save Installments</a-button>
  </a-space>
</template>

<script>
import { reactive } from 'vue'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    width: '5%',
    slots: { customRender: 'index' }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: '8%',
    slots: { customRender: 'amount' }
  },
  {
    title: 'Due date',
    dataIndex: 'due',
    width: '12%',
    slots: { customRender: 'due' }
  },
  {
    title: 'Repaid',
    dataIndex: 'status',
    width: '12%',
    slots: { customRender: 'status' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '10%'
  }
]

export default {
  name: 'InstallmentEditor',

  props: {
    data: {
      type: Array,
      default: () => []
    },
    loanId: Number,
    bizId: Number
  },

  emits: ['saveInstallment'],

  setup (props) {
    const loanEditor = reactive(
      props.data || []
    )

    return {
      loanEditor
    }
  },

  data () {
    return {
      columns,
      editableData: []
    }
  },

  mounted () {
    this.editableData = cloneDeep(this.loanEditor)
  },

  methods: {
    getTotalAmount () {
      let total = 0
      if (this.loanEditor && this.loanEditor.length > 0) {
        this.loanEditor.forEach(item => {
          total += item.amount
        })
      }
      return total
    },

    add () {
      const newInstallment = {
        amount: '',
        due: null,
        status: 0
      }

      this.loanEditor.push(newInstallment)
      this.editableData.push(newInstallment)
    },

    save () {
      for (let i = 0; i < this.editableData.length; i++) {
        const item = this.editableData[i]
        if (item.amount <= 0) {
          message.error('Amount of installment must be greater than 0')
          return
        }

        if (item.due === null || item.due === undefined) {
          message.error('Repay date must be set')
          return
        }
      }

      this.$emit('saveInstallment', this.loanId, this.getTotalAmount(), this.editableData, this.bizId)
    },
    del (key) {
      this.loanEditor.splice(key, 1)
      this.editableData.splice(key, 1)
    },

    showDate
  }
}
</script>
