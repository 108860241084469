<template>
  <a-space size="large" style="margin: 1rem 0;">
    <a-button type="primary" @click="create">New Loan</a-button>
    <a-divider type="vertical" />
    <span style="font-weight: bold;">Repay back to company: <span style="color: red">{{ total }}</span></span>
    <a-divider type="vertical" />
    <span style="font-weight: bold;">Receive from company: <span style="color: red">{{ totalIncome }}</span></span>
  </a-space>
  <a-table :loading="loading" :columns="columns" :data-source="loans" size="small">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>
    <template v-for="col in ['amount']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input-number
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template v-for="col in ['purpose', 'comment']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          <a-tooltip :title="text">
            <span>{{ text }}</span>
          </a-tooltip>
        </template>
      </div>
    </template>
    <template v-for="col in ['start', 'due']" :key="col" #[col]="{ text, record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            :allow-clear="false"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ showDate(text, false) }}
        </template>
      </div>
    </template>
    <template #status="{ text: status, record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].status">
        <a-select-option :value="0">Not repaid yet</a-select-option>
        <a-select-option :value="1">Already Repaid</a-select-option>
      </a-select>
      <a-tag v-else :color="status === LoanStatusCode.NO_REPAY ? 'pink' : 'green'">
        {{ status === LoanStatusCode.NO_REPAY ? 'N' : 'Y' }}
      </a-tag>
    </template>
    <template #loan="{ text: loan, record }">
      <a-select
          v-if="editableData[record.id]"
          v-model:value="editableData[record.id].loan"
          style="width: 100%;">
        <a-select-option :value="true">Receivables (Staff need repay back)</a-select-option>
        <a-select-option :value="false">Debt (Company need pay in future)</a-select-option>
      </a-select>
      <a-tag v-else :color="loan === true ? 'green' : 'pink'">
        {{ loan === true ? 'Y' : 'N' }}
      </a-tag>
    </template>
    <template v-for="col in ['create_at']" :key="col" #[col]="{ text }">
      {{ showDate(text, false) }}
    </template>
    <template #operation="{ record }">
      <div>
        <span v-if="editableData[record.id]">
          <a @click="save(record.id)">Save</a>
          <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
        </span>
        <a-button v-else @click="edit(record.id)">Edit</a-button>
      </div>
    </template>
    <template #expandedRowRender="{ record }">
      <a-row>
        <a-col :xs="24">
          <InstallmentEditor :data="record.installment" :loanId="record.id"  @saveInstallment="saveInstallment"></InstallmentEditor>
        </a-col>
      </a-row>
    </template>
  </a-table>

  <a-modal
      title="New Loan"
      centered
      v-model:visible="visible"
      @ok="newSubmit"
      @cancel="newReset"
      okText="Save"
  >
    <a-form :rules="rules" :model="newItem" ref="newForm">

      <a-form-item name="purpose" hasFeedback required>
        <a-input
            placeholder="Purpose"
            v-model:value="newItem.purpose"
        />
      </a-form-item>

      <a-form-item name="amount" hasFeedback required>
        <a-input-number
            placeholder="Amount"
            v-model:value="newItem.amount"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="loan" hasFeedback required>
        <a-select
            v-model:value="newItem.loan"
            style="width: 100%;">
          <a-select-option :value="true">Receivables (Staff need repay back)</a-select-option>
          <a-select-option :value="false">Debt (Company need pay in future)</a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="range" required>
        <a-range-picker
            :placeholder="['Start Date', 'Due Date']"
            format="YYYY-MM-DD"
            v-model:value="newItem.range"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="comment" hasFeedback required>
        <a-textarea
            placeholder="Comments"
            v-model:value="newItem.comment"
            allowClear
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { cloneDeep } from 'lodash-es'
import { createStaffLoanInfo, updateStaffLoanInfo, getStaffLoanInfo } from '../../api/staff'
import { message } from 'ant-design-vue'
import { LoanStatusCode } from '../../api/codes'
import InstallmentEditor from './InstallmentEditor'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    width: '15%',
    slots: { customRender: 'purpose' },
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    slots: { customRender: 'amount' },
    width: '7%'
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    ellipsis: true
  },
  {
    title: 'Due',
    dataIndex: 'due',
    width: '10%',
    slots: { customRender: 'due' },
    ellipsis: true
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    width: '20%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Receivables?',
    dataIndex: 'loan',
    width: '13%',
    slots: { customRender: 'loan' }
  },
  {
    title: 'Clear?',
    dataIndex: 'status',
    width: '10%',
    slots: { customRender: 'status' }
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    width: '10%',
    slots: { customRender: 'operation' }
  }
]

export default {
  name: 'StaffLoan',
  components: { InstallmentEditor },
  setup () {
    const route = useRoute()
    const store = useStore()
    const staffId = route.query.staffId || undefined
    const user = computed(() => store.state.auth.user)

    return {
      staffId,
      user
    }
  },

  created () {
    this.loadLoanInfo()
  },

  data () {
    return {
      columns,

      editableData: [],

      loans: [],

      loading: false,
      visible: false,

      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },

      newItem: {
        range: [],
        loan: true,
        purpose: '',
        comment: '',
        amount: undefined
      },

      rules: {
        amount: [
          {
            required: true,
            type: 'number'
          }
        ],
        range: [
          {
            required: true,
            type: 'array'
          }
        ],
        comment: [
          {
            required: true
          }
        ],
        loan: [
          {
            required: true,
            type: 'boolean'
          }
        ]
      },

      LoanStatusCode: LoanStatusCode,

      total: 0,

      totalIncome: 0
    }
  },

  methods: {
    loadLoanInfo () {
      this.loading = true
      getStaffLoanInfo({
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.loans = res.data.loans
          this.updateTotalLoans()
        } else {
          message.error('Failed to load staff loan info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },

    updateTotalLoans () {
      let _loan = 0.0
      let _income = 0.0
      this.loans.forEach(item => {
        if (item.status !== this.LoanStatusCode.REPAID) {
          if (item.loan) {
            _loan += item.amount
          } else {
            _income += item.amount
          }
        }
      })
      this.total = _loan
      this.totalIncome = _income
    },

    create () {
      this.visible = true
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.loans.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      const params = {
        id: key,
        purpose: this.editableData[key].purpose,
        comment: this.editableData[key].comment,
        amount: this.editableData[key].amount,
        start: this.editableData[key].start,
        due: this.editableData[key].due,
        loan: this.editableData[key].loan,
        status: this.editableData[key].status,
        modifier_id: this.userId
      }

      updateStaffLoanInfo(params).then(res => {
        if (res.code === 0) {
          const changed = this.loans.filter(item => key === item.id)[0]
          Object.assign(changed, this.editableData[key])
          delete this.editableData[key]
          this.updateTotalLoans()
          message.success('Successfully update staff loan info')
        } else {
          message.error('Failed to update staff loan info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      })
    },

    // repay (key) {
    //   const params = {
    //     id: key,
    //     status: this.LoanStatusCode.REPAID,
    //     modifier_id: this.userId
    //   }
    //   updateStaffLoanInfo(params).then(res => {
    //     if (res.code === 0) {
    //       const changed = this.loans.filter(item => key === item.id)[0]
    //       changed.status = this.LoanStatusCode.REPAID
    //       delete this.editableData[key]
    //       this.updateTotalLoans()
    //       message.success('Successfully repaid staff loan info')
    //     } else {
    //       message.error('Failed to repaid staff loan info, please retry')
    //     }
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },

    newSubmit () {
      console.log(this.$refs.newForm.values)
      this.$refs.newForm.validate().then(() => {
        createStaffLoanInfo({
          staff_id: this.staffId,
          purpose: this.newItem.purpose,
          amount: this.newItem.amount,
          comment: this.newItem.comment,
          start: this.newItem.range[0],
          due: this.newItem.range[1],
          loan: this.newItem.loan,
          creator_id: this.user.id,
          approver_id: this.user.id
        }).then(res => {
          if (res.code === 0) {
            this.visible = false
            message.success('Successfully created new loan, you can add installments now.')
            setTimeout(() => {
              this.loadLoanInfo()
            }, 500)
          } else {
            message.error('Failed to create new loan, please try again later')
          }
        }).catch(err => {
          message.error('Probably network broken, please try again later')
          console.log(err)
        })
      })
    },

    newReset () {
      this.visible = false
      this.$refs.newForm.resetFields()
    },

    saveInstallment (loanId, total, installments) {
      const params = {
        id: loanId,
        amount: total,
        installment: installments,
        modifier_id: this.user.id
      }
      updateStaffLoanInfo(params).then(res => {
        if (res.code === 0) {
          message.success('Loan installments have been saved')
          setTimeout(() => {
            this.$router.push({
              path: '/staff/staff_info',
              query: {
                staffId: this.staffId,
                page: 'loan'
              }
            })
          }, 1000)
        } else {
          message.error('Failed to save loan installments, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    showDate
  }

}
</script>

<style scoped>

</style>
