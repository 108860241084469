<template>
  <a-divider orientation="left">
    Loan list
  </a-divider>

  <a-table :columns="columns" :data-source="loans" :pagination="pager" rowKey="id" size="small"
           style="margin-top: 1rem;" @change="onChange">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>
    <template #name="{ text, record }">
      <a :href="`/staff/staff_info?staffId=${record.staff_id}&page=loan`" target="_blank">{{ text }}</a>
    </template>
    <template v-for="col in ['start', 'due']" :key="col" #[col]="{ text }">
        {{ showDate(text, false) }}
    </template>
    <template v-for="col in ['comment']" :key="col" #[col]="{ text }">
      <a-tooltip :title="text">
        <span>{{ text }}</span>
      </a-tooltip>
    </template>
    <template #expandedRowRender="{ record }">
      <InstallmentDisplay v-if="record.installment && record.installment.length > 0" :data="record.installment"></InstallmentDisplay>
      <span v-else>No installments</span>
    </template>
  </a-table>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { getLoanList } from '../../api/staff'
import { message } from 'ant-design-vue'
import { showDate } from '../../utils/util'
import InstallmentDisplay from '../../components/staff/InstallmentDisplay'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '10%',
    slots: { customRender: 'name' },
    ellipsis: true
  },
  {
    title: 'Purpose',
    dataIndex: 'purpose',
    width: '20%',
    slots: { customRender: 'purpose' },
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: '10%',
    slots: { customRender: 'amount' },
    sorter: (a, b) => a.amount - b.amount,
    ellipsis: true
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    ellipsis: true
  },
  {
    title: 'Due',
    dataIndex: 'due',
    width: '10%',
    slots: { customRender: 'due' },
    sorter: (a, b) => a.due - b.due
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    width: '10%',
    slots: { customRender: 'creator' },
    ellipsis: true
  },
  {
    title: 'Approver',
    dataIndex: 'approver',
    width: '10%',
    slots: { customRender: 'approver' },
    ellipsis: true
  },
  {
    title: 'Comments',
    dataIndex: 'comment',
    width: '15%',
    slots: { customRender: 'comment' },
    ellipsis: true
  }
]

export default {
  name: 'LoanList',
  components: { InstallmentDisplay },
  setup () {
    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  mounted () {
    this.loadLoanList()
  },

  data () {
    return {
      loans: [],
      columns,
      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },
      filters: {},
      sorters: []
    }
  },

  methods: {
    loadLoanList () {
      const params = {
        pager: {
          ...this.pager,
          offset: (this.pager.current - 1) * this.pager.pageSize,
          limit: this.pager.pageSize
        },
        filters: this.filters,
        sorters: this.sorters
      }

      this.$loading.show('Loading loan list...')

      getLoanList(params).then(res => {
        if (res.code === 0) {
          this.loans = res.data.loans
          this.pager.total = res.data.total
        } else {
          message.error('Failed to load loan list, please retry')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$loading.hide()
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination

      console.log('Remember transform table filters to filters')
      this.filters = {}

      console.log(tableSorters)

      this.sorters = tableSorters

      this.loadLoanList()
    },

    showDate
  }
}
</script>

<style scoped>

</style>
