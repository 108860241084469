<template>
  <a-divider orientation="left">
    Candidate list
  </a-divider>
  <a-table :columns="columns" :data-source="candidates" :pagination="pager" rowKey="id" size="small" style="margin-top: 1rem;" @change="onChange">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1}}
    </template>
    <template v-for="col in ['first_name', 'last_name', 'phone']" #[col]="{ text, record }" :key="col">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #passport="{ record }">
      <img :src="getPictureUrl(record.passport_picture)" style="height: 1rem; width: 1rem; cursor: pointer;" @click="showPicture(record.passport_picture)">
    </template>
    <template #age="{ record }">
      {{
        showBirthday(record.birthday)
      }}
    </template>

    <template #expandedRowRender="{ record }">
      <a-row style="padding-top: 2rem;">
        <a-col :xs="24" :sm="12">
          <p>
            <span class="title">Email: </span><span>{{ record.email }}</span>
          </p>
          <p>
            <span class="title">Birthday: </span>
            <span>{{ showBirthday(record.birthday, false) }}</span>
          </p>
          <p>
            <span class="title">Applied: </span><span>{{ showDiffDays(record.create_at) }}</span>
          </p>
          <p>
            <span class="title">Skills:</span><span>{{ record.skills }}</span>
          </p>
          <p>
            <span class="title">Send Message: </span><span><a :href="`//api.whatsapp.com/send?phone=${record.phone.split(' ').join('')}`" target="_blank">Whatsapp</a></span>
          </p>
        </a-col>
      </a-row>
    </template>
<!--    <template #operation="{ record }">-->
<!--      <div class="editable-row-operations">-->
<!--        <span v-if="editableData[record.id]">-->
<!--          <a @click="save(record.id)">Save</a>-->
<!--          <a @click="cancel(record.id)" style="margin-left: 1rem;">Cancel</a>-->
<!--        </span>-->
<!--        <span v-else>-->
<!--          <a-button @click="edit(record.id)">Edit</a-button>-->
<!--        </span>-->
<!--      </div>-->
<!--    </template>-->
  </a-table>
</template>

<script>

import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { getCandidateList, updateCandidateInfo } from '../../api/staff'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    width: '15%',
    slots: { customRender: 'first_name' },
    ellipsis: true
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    width: '15%',
    slots: { customRender: 'last_name' },
    ellipsis: true
  },
  {
    title: 'Nationality',
    dataIndex: 'nation',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Age',
    dataIndex: 'age',
    width: '5%',
    slots: { customRender: 'age' }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    width: '20%',
    slots: { customRender: 'phone' }
  },
  {
    title: 'Referer',
    dataIndex: 'referer_name',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Passport',
    width: '10%',
    slots: { customRender: 'passport' }
  }
  // {
  //   title: 'OP',
  //   dataIndex: 'operation',
  //   slots: { customRender: 'operation' },
  //   width: '20%'
  // }
]

export default {
  name: 'CandidateList',

  setup () {
    const store = useStore()

    const userId = computed(() => store.state.auth.user.id)
    const candidateLoadingParams = computed(() => store.state.transition.candidateLoadingParams)

    const cacheCandidateLoadingInfo = ({
      pager,
      filters,
      sorters,
      search
    }) => store.dispatch('transition/cacheCandidateLoadingInfo', {
      pager,
      filters,
      sorters,
      search
    })

    return {
      userId,
      candidateLoadingParams,
      cacheCandidateLoadingInfo
    }
  },

  mounted () {
    this.loadCandidateList()
  },

  data () {
    return {
      columns,
      editableData: {},
      candidates: [],
      search: this.candidateLoadingParams?.search || '',
      pager: this.candidateLoadingParams?.pager || { pageSize: 10, total: 0, current: 1 },
      filters: this.candidateLoadingParams?.filters || {},
      sorters: this.candidateLoadingParams?.sorters || []
    }
  },

  methods: {
    loadCandidateList () {
      const params = {
        pager: { ...this.pager, offset: (this.pager.current - 1) * this.pager.pageSize, limit: this.pager.pageSize },
        filters: { ...this.filters },
        sorters: this.sorters,
        search: this.search
      }

      this.$loading.show('Loading candidate list...')

      getCandidateList(params).then(res => {
        if (res.code === 0) {
          this.cacheCandidateLoadingInfo(params)
          this.candidates = res.data.candidates
          this.pager.total = res.data.total
        }
      }).catch(err => {
        console.log(err)
        message.error('Failed to load candidate list, please retry')
      }).finally(() => {
        this.$loading.hide()
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination

      console.log('Remember transform table filters to filters')
      this.filters = {}

      this.sorters = tableSorters

      this.loadCandidateList()
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.candidates.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      updateCandidateInfo({
        id: key,
        name: this.editableData[key].name,
        phone: this.editableData[key].phone,
        skills: this.editableData[key].skills
      }).then(res => {
        if (res.code === 0) {
          Object.assign(this.candidates.filter(item => key === item.id)[0], this.editableData[key])
          delete this.editableData[key]
        } else {
          message.error('Failed to update candidate info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    showBirthday (date, showAge = true) {
      if (showAge) {
        return moment().diff(date, 'years', false)
      } else {
        return moment(date).format('YYYY-MM-DD')
      }
    },

    getPictureUrl (imgFile) {
      return '/img/passport/' + imgFile
    },

    showPicture (imgFile) {
      this.$preview(this.getPictureUrl(imgFile))
    },

    showDiffDays (date) {
      const diffDays = moment().diff(date, 'days', false)
      if (diffDays === 0) {
        return 'Today'
      } else if (diffDays === 1) {
        return 'Yesterday'
      } else {
        return `${diffDays} days ago`
      }
    }
  }
}
</script>

<style scoped>

.title {
  font-weight: bold;
  color: #aaa;
  width: 15rem;
}

</style>
