import moment from 'moment'

export function checkEmailFormat (email) {
  // 验证邮箱的正则表达式
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)) {
    // 合法的邮箱
    return true
  }
  return false
}

export function getInfoFromEmail (email) {
  if (email) {
    return email.split('@')[0]
  } else {
    return ''
  }
}

export function getHostFromEmail (email) {
  if (email) {
    return 'http://' + email.split('@')[1]
  } else {
    return ''
  }
}

export function isNumber (value) {
  return typeof value === 'number' && !isNaN(value)
}

export function compareString (a, b) {
  if (a.attr < b.attr) {
    return -1
  }
  if (a.attr > b.attr) {
    return 1
  }
  return 0
}

export function checkIfDuplicateExists (w) {
  return new Set(w).size !== w.length
}

export function getCurrentDate () {
  const utcMoment = moment.utc()
  return new Date(utcMoment.format())
}

export function showTime (date) {
  return moment(date).format('HH:mm:ss')
}

export function showDate (date, showAge = true) {
  if (!date) {
    return null
  }

  if (showAge) {
    return moment().diff(date, 'years', false)
  } else {
    return moment(date).format('YYYY-MM-DD')
  }
}

export function toThousands (value) {
  let num = (value || 0).toString(); let result = ''
  while (num.length > 3) {
    result = ',' + num.slice(-3) + result
    num = num.slice(0, num.length - 3)
  }
  if (num) {
    result = num + result
  }
  return result
}
