<template>
  <a-table rowKey="index" :data-source="installments" :columns="columns" :pagination="false"  size="small">
    <template #index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-for="col in ['amount']" :key="col" #[col]="{ text }">
      {{ text }}
    </template>
    <template v-for="col in ['due']" :key="col" #[col]="{ record }">
          {{ showDate(record.due, false) }}
    </template>
    <template #status="{ text: status }">
        {{ status === 0 ? 'Not Repaid Yet' : 'Already Repaid' }}
    </template>
    <template #footer>
      <a-space size="large" style="margin-bottom: 1rem;">
        <span>Total: {{ getTotalAmount() }} </span>
        <span>Installments: {{ installments ? installments.length : 0 }}</span>
      </a-space>
    </template>
  </a-table>
</template>

<script>
import { reactive } from 'vue'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    width: '5%',
    slots: { customRender: 'index' }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: '8%',
    slots: { customRender: 'amount' }
  },
  {
    title: 'Due date',
    dataIndex: 'due',
    width: '12%',
    slots: { customRender: 'due' }
  },
  {
    title: 'Repaid',
    dataIndex: 'status',
    width: '12%',
    slots: { customRender: 'status' }
  }
]

export default {
  name: 'InstallmentDisplay',

  props: {
    data: {
      type: Array,
      default: () => []
    }
  },

  setup (props) {
    const installments = reactive(
      props.data || []
    )

    return {
      installments
    }
  },

  data () {
    return {
      columns
    }
  },

  methods: {
    getTotalAmount () {
      let total = 0
      if (this.installments && this.installments.length > 0) {
        this.installments.forEach(item => {
          total += item.amount
        })
      }
      return total
    },
    showDate
  }
}
</script>
