import request from '@/utils/request'

const staffApi = {
  GetCandidateList: '/staff/candidate_list',
  UpdateCandidateInfo: '/staff/update_candidate',

  GetPersonalMonthlyAttendance: '/staff/personal_attendance',
  GetMonthlyAttendance: '/staff/monthly_attendance',
  UpdateAttendanceResult: '/staff/update_attendance',
  GetAllStaffForAttendanceCheck: '/staff/staff_attendance_list',

  GetStaffList: '/staff/staff_list',
  GetStaffListWithSalary: '/staff/staff_list_ws',
  GetStaffSalaryAndPayroll: '/staff/salary_payroll',
  GetStaffSalary: '/staff/salaries',
  UpdateStaffSalary: '/staff/update_salary',
  GetStaffPayroll: '/staff/payrolls',
  UpdateStaffPayroll: '/staff/update_payroll',
  CreateStaffSalaryInfo: '/staff/create_salary',

  CreateStaff: '/staff/create_staff',
  UpdateStaffInfo: '/staff/update_staff',
  GetStaffInfo: '/staff/staff_info',
  UpdateStaffLeaveInfo: '/staff/update_leave',
  UpdateStaffLoanInfo: '/staff/update_loan',

  GetStaffLeaveInfo: '/staff/get_leaves',
  CreateStaffLeaveInfo: '/staff/create_leave',

  GetLoanList: '/staff/loan_list',
  GetStaffLoanInfo: '/staff/get_loans',
  CreateStaffLoanInfo: '/staff/create_loan',

  GetVisaList: '/staff/visa_list',
  GetStaffVisaInfo: '/staff/get_visas',
  CreateStaffVisaInfo: '/staff/create_visa',
  UpdateStaffVisaInfo: '/staff/update_visa',
  UpdateStaffVisaInstallment: '/staff/update_visa_installment',

  GetStaffPassportInfo: '/staff/get_passport',
  CreateStaffPassportInfo: '/staff/create_passport',
  UpdateStaffPassportInfo: '/staff/update_passport',

  ResignStaff: '/staff/resign',
  UploadAvatar: '/staff/upload_avatar',

  GetAttendanceExceptions: '/staff/att_exceptions'
}

export function getAttendanceExceptions (params) {
  return request({
    url: staffApi.GetAttendanceExceptions,
    method: 'post',
    data: params
  })
}

export function uploadStaffAvatar (params) {
  return request({
    url: staffApi.UploadAvatar,
    method: 'post',
    data: params
  })
}

export function getVisaList (params) {
  return request({
    url: staffApi.GetVisaList,
    method: 'post',
    data: params
  })
}

export function resignStaff (params) {
  return request({
    url: staffApi.ResignStaff,
    method: 'post',
    data: params
  })
}

export function getPersonalMonthlyAttendance (params) {
  return request({
    url: staffApi.GetPersonalMonthlyAttendance,
    method: 'post',
    data: params
  })
}

export function getMonthlyAttendance (params) {
  return request({
    url: staffApi.GetMonthlyAttendance,
    method: 'post',
    data: params
  })
}

export function updateAttendanceResult (params) {
  return request({
    url: staffApi.UpdateAttendanceResult,
    method: 'post',
    data: params
  })
}

export function getAllStaffForAttendanceCheck (params) {
  return request({
    url: staffApi.GetAllStaffForAttendanceCheck,
    method: 'post',
    data: params
  })
}

export function getLoanList (params) {
  return request({
    url: staffApi.GetLoanList,
    method: 'post',
    data: params
  })
}

export function getStaffPassportInfo (params) {
  return request({
    url: staffApi.GetStaffPassportInfo,
    method: 'post',
    data: params
  })
}

export function createStaffPassportInfo (params) {
  return request({
    url: staffApi.CreateStaffPassportInfo,
    method: 'post',
    data: params
  })
}

export function createStaff (params) {
  return request({
    url: staffApi.CreateStaff,
    method: 'post',
    data: params
  })
}

export function updateStaffPassportInfo (params) {
  return request({
    url: staffApi.UpdateStaffPassportInfo,
    method: 'post',
    data: params
  })
}

export function updateStaffVisaInstallment (params) {
  return request({
    url: staffApi.UpdateStaffVisaInstallment,
    method: 'post',
    data: params
  })
}

export function updateStaffVisaInfo (params) {
  return request({
    url: staffApi.UpdateStaffVisaInfo,
    method: 'post',
    data: params
  })
}

export function getStaffVisaInfo (params) {
  return request({
    url: staffApi.GetStaffVisaInfo,
    method: 'post',
    data: params
  })
}

export function getStaffLoanInfo (params) {
  return request({
    url: staffApi.GetStaffLoanInfo,
    method: 'post',
    data: params
  })
}

export function getStaffLeaveInfo (params) {
  return request({
    url: staffApi.GetStaffLeaveInfo,
    method: 'post',
    data: params
  })
}

export function createStaffVisaInfo (params) {
  return request({
    url: staffApi.CreateStaffVisaInfo,
    method: 'post',
    data: params
  })
}

export function createStaffLoanInfo (params) {
  return request({
    url: staffApi.CreateStaffLoanInfo,
    method: 'post',
    data: params
  })
}

export function createStaffLeaveInfo (params) {
  return request({
    url: staffApi.CreateStaffLeaveInfo,
    method: 'post',
    data: params
  })
}

export function updateStaffLoanInfo (params) {
  return request({
    url: staffApi.UpdateStaffLoanInfo,
    method: 'post',
    data: params
  })
}

export function updateStaffLeaveInfo (params) {
  return request({
    url: staffApi.UpdateStaffLeaveInfo,
    method: 'post',
    data: params
  })
}

export function getStaffList (params) {
  return request({
    url: staffApi.GetStaffList,
    method: 'post',
    data: params
  })
}

export function getStaffListWithSalary (params) {
  return request({
    url: staffApi.GetStaffListWithSalary,
    method: 'post',
    data: params
  })
}

export function getStaffSalaryAndPayroll (params) {
  return request({
    url: staffApi.GetStaffSalaryAndPayroll,
    method: 'post',
    data: params
  })
}

export function updateStaffSalary (params) {
  return request({
    url: staffApi.UpdateStaffSalary,
    method: 'post',
    data: params
  })
}

export function getStaffSalary (params) {
  return request({
    url: staffApi.GetStaffSalary,
    method: 'post',
    data: params
  })
}

export function updateStaffPayroll (params) {
  return request({
    url: staffApi.UpdateStaffPayroll,
    method: 'post',
    data: params
  })
}

export function getStaffPayroll (params) {
  return request({
    url: staffApi.GetStaffPayroll,
    method: 'post',
    data: params
  })
}

export function createStaffSalaryInfo (params) {
  return request({
    url: staffApi.CreateStaffSalaryInfo,
    method: 'post',
    data: params
  })
}

export function getStaffInfo (params) {
  return request({
    url: staffApi.GetStaffInfo,
    method: 'post',
    data: params
  })
}

export function updateStaffInfo (params) {
  return request({
    url: staffApi.UpdateStaffInfo,
    method: 'post',
    data: params
  })
}

export function getCandidateList (params) {
  return request({
    url: staffApi.GetCandidateList,
    method: 'post',
    data: params
  })
}

export function updateCandidateInfo (params) {
  return request({
    url: staffApi.UpdateCandidateInfo,
    method: 'post',
    data: params
  })
}
