<template>
  <a-descriptions
      :column="4"
      bordered
      title="Staff Info"
      size="small"
  >
    <a-descriptions-item label="Name">{{ staff.first_name }} {{ staff.last_name }}</a-descriptions-item>
    <a-descriptions-item label="Staff ID">{{ staff.card_id }}</a-descriptions-item>
    <a-descriptions-item label="Join Date">{{ showDate(staff.join_date, false) }}</a-descriptions-item>
    <a-descriptions-item label="Birthday">{{ showDate(staff.birthday, false) }} ({{ showDate(staff.birthday, true) }})</a-descriptions-item>
    <a-descriptions-item label="Nationality">{{ staff.nation }}</a-descriptions-item>
    <a-descriptions-item label="Referrer">{{ staff.referrer }}</a-descriptions-item>
    <a-descriptions-item label="Phone">{{ staff.phone }}</a-descriptions-item>
    <a-descriptions-item label="Whatsapp">{{ staff.whatsapp }}</a-descriptions-item>
    <a-descriptions-item label="Email">{{ staff.email }}</a-descriptions-item>
    <a-descriptions-item :span="3" label="Comments">{{ staff.comment }}</a-descriptions-item>
      <a-descriptions-item label="Avatar">
          <StaffAvatar :bizId=staff.id :imgUrl=staff.avatar></StaffAvatar>
      </a-descriptions-item>
  </a-descriptions>
  <a-tabs v-model:activeKey="activeKey" style="margin-top: 1rem;">
    <a-tab-pane key="1" tab="Leaves">
      <StaffLeave :leave_types="leave_types"/>
    </a-tab-pane>

    <a-tab-pane key="2" tab="Loans">
      <StaffLoan></StaffLoan>
    </a-tab-pane>

    <a-tab-pane key="3" tab="Visas">
      <StaffVisa :companies="companies" :visa_types="visa_types" :countries="countries"/>
    </a-tab-pane>

    <a-tab-pane key="4" tab="Passport">
      <StaffPassport/>
    </a-tab-pane>

    <a-tab-pane key="5" tab="Monthly Attendance">
        <a :href="`/staff/attendance?staffId=${staffId}`" target="_blank">View monthly attendance data</a>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { useRoute } from 'vue-router'
import { getStaffInfo } from '../../api/staff'
import { message } from 'ant-design-vue'
import StaffLeave from '../../components/staff/StaffLeave'
import StaffLoan from '../../components/staff/StaffLoan'
import StaffVisa from '../../components/staff/StaffVisa'
import StaffPassport from '../../components/staff/StaffPassport'
import StaffAvatar from '../../components/staff/StaffAvatar'
import { showDate } from '../../utils/util'
// import { ref } from 'vue'

const pages = {
  leave: '1',
  loan: '2',
  visa: '3',
  passport: '4',
  monthly: '5'
}

export default {
  name: 'StaffInfo',
  components: { StaffAvatar, StaffPassport, StaffVisa, StaffLoan, StaffLeave },
  setup () {
    const route = useRoute()
    const staffId = route.query.staffId || undefined
    const current = route.query.page || 'leave'

    return {
      staffId,
      current
    }
  },

  data () {
    return {
      activeKey: pages[this.current],
      staff: {},
      avatar: '',
      leave_types: [],
      visa_types: [],
      companies: [],
      countries: []
    }
  },

  mounted () {
    this.loadStaffInfo()
  },

  methods: {
    loadStaffInfo () {
      this.$loading.show('Loading staff info...')
      getStaffInfo({ staffId: this.staffId }).then(res => {
        if (res.code === 0) {
          if (res.data) {
            res.data.staff.avatar = this.getPictureUrl(res.data.staff.avatar)
            this.staff = res.data.staff
            this.leave_types = res.types.leave_types
            this.visa_types = res.types.visa_types
            this.companies = res.types.companies
            this.countries = res.countries
          } else {
            message.warn(`Staff with ID: ${this.staffId} not found, please check`)
          }
        } else {
          message.error('Failed to load staff info, please try again later')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$loading.hide()
      })
    },

    getPictureUrl (imgFile) {
      if (imgFile) {
        return '/img/avatar/' + imgFile
      } else {
        return undefined
      }
    },

    showDate
  }
}
</script>

<style scoped>

</style>
