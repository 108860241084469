<template>
  <a-layout id="layout-responsive" :hasSider="true">
    <a-layout-sider
      breakpoint="lg"
      theme="light"
      collapsed-width="0"
      @breakpoint="onBreakpoint"
      :class="{'menu-slider': isFloat}"
      :zeroWidthTriggerStyle="{'top': '11px'}"
      mode="inline"
      @click="handleClick"
    >
      <div class="logo">
        <img src="../../assets/logo.png" style="width:8rem;">
      </div>
      <a-menu theme="light" mode="inline"
              :defaultOpenKeys="defaultOpenKeys" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys"
              @select="handleMenuSelected"
              style="text-align: left;">
        <a-sub-menu key="/dashboard" @titleClick="titleClick">
          <template #title>
            <span><PieChartOutlined/><span>Dashboard</span></span>
          </template>
          <a-menu-item key="/dashboard/attendance">Attendance</a-menu-item>
        </a-sub-menu>

        <a-sub-menu key="/staff" @titleClick="titleClick">
          <template #title>
            <span><ReconciliationOutlined/><span>Staff</span></span>
          </template>
          <a-menu-item key="/staff/staff_list">Staff List</a-menu-item>
          <a-menu-item key="/staff/loan_list">Loan List</a-menu-item>
          <a-menu-item key="/staff/visa_list">Visa List</a-menu-item>
          <a-menu-item key="/staff/resign_list">Resign List</a-menu-item>
          <a-menu-item key="/staff/payroll">Payroll</a-menu-item>
<!--          <a-menu-item key="/staff/attendance">Daily Attendance</a-menu-item>-->
        </a-sub-menu>

        <a-sub-menu key="/staff" @titleClick="titleClick">
          <template #title>
            <span><ReconciliationOutlined/><span>Candidate</span></span>
          </template>
          <a-menu-item key="/staff/candidate_list">Candidate List</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="/staff/settings">
          <setting-outlined/>
          <span class="nav-text">Settings</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        style="background: #fff;padding: 0 1rem;display: flex; justify-content: flex-end; align-items: center;  }">
        <AvatarDropdown></AvatarDropdown>
      </a-layout-header>

      <a-layout-content style="margin: 24px 16px 0;text-align: left">
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          <router-view/>
        </div>
      </a-layout-content>
      <a-layout-footer style="textAlign: center">
        ©2019-2021 All rights reserved. REER Electronics LLC
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import {
  // UserOutlined,
  // ShoppingCartOutlined,
  SettingOutlined,
  PieChartOutlined,
  ReconciliationOutlined
} from '@ant-design/icons-vue'
import AvatarDropdown from '@/components/user/AvatarDropdown'

import { Menu, Layout, Select } from 'ant-design-vue'

// import getters from "@/store/getters";

export default {
  name: 'HomeLayout',
  components: {
    AvatarDropdown,
    // UserOutlined,
    // VideoCameraOutlined,
    // ShoppingCartOutlined,
    SettingOutlined,
    PieChartOutlined,
    ReconciliationOutlined,

    [Layout.name]: Layout,
    [Layout.Sider.name]: Layout.Sider,
    [Layout.Header.name]: Layout.Header,
    [Layout.Content.name]: Layout.Content,
    [Layout.Footer.name]: Layout.Footer,
    [Menu.name]: Menu,
    [Menu.Item.name]: Menu.Item,
    [Menu.SubMenu.name]: Menu.SubMenu,
    [Select.name]: Select
  },

  data () {
    return {
      isFloat: false,
      zeroTriggerClass: '{top:0}',
      selectedKeys: [this.$route.path],
      openKeys: [this.$route.path.split('/').slice(0, 2).join('/')],
      defaultOpenKeys: ['/inventory']
    }
  },

  methods: {
    // onCollapse (collapsed, type) {
    //   // console.log('###########Collapse')
    //   console.log(collapsed, type)
    //   // console.log('###########Collapse')
    //   // this.isFloat = !this.isFloat;
    // },
    onBreakpoint (broken) {
      this.isFloat = broken
    },
    titleClick () {

      // console.log('titleClick', e);
    },
    handleClick () {

      // console.log('click', e);
    },

    handleMenuSelected (menuItem) {
      // menuItem {item, key, keyPath}
      this.$router.push({ path: menuItem.key })
    }
  }
}
</script>

<style>
#layout-responsive .logo {
  height: 32px;
  /*background: rgba(255, 255, 255, 0.9);*/
  margin: 16px;
  /*color: black;*/
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
}

.float-menu {
  position: absolute;
  left: 0;
}

.menu-slider {
  position: absolute;
  height: 100%;
  background: #f5f5f5;
  z-index: 9999;
}

</style>
