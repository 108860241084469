<template>
  <a-table :loading="loading" :columns="columns" :data-source="leaves" size="small">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>
    <template v-for="col in ['reason', 'comment']" :key="col" #[col]="{ text }">
          {{ text }}
    </template>
    <template #start="{ record }">
        {{
          showDate(record.start, false)
        }}
    </template>
    <template #end="{ record }">
        {{
          showDate(record.end, false)
        }}
    </template>
    <template v-for="col in ['create_at']" :key="col" #[col]="{ text }">
      {{ showDate(text, false) }}
    </template>
  </a-table>
</template>

<script>

import { getStaffLeaveInfo } from '../../api/staff'
import { message } from 'ant-design-vue'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Type',
    dataIndex: 'leave_type',
    width: '15%',
    slots: { customRender: 'leave_type' },
    ellipsis: true
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    width: '15%',
    slots: { customRender: 'reason' },
    ellipsis: true
  },
  {
    title: 'Comments',
    dataIndex: 'comment',
    width: '15%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    ellipsis: true
  },
  {
    title: 'End',
    dataIndex: 'end',
    width: '10%',
    slots: { customRender: 'end' },
    ellipsis: true
  },
  {
    title: 'Approver',
    dataIndex: 'approver',
    width: '15%',
    ellipsis: true
  },
  {
    title: 'Recorded',
    dataIndex: 'create_at',
    width: '10%',
    slots: { customRender: 'create_at' }
  },
  {
    title: 'Op',
    dataIndex: 'operation',
    width: '10%',
    slots: { customRender: 'operation' }
  }
]

export default {
  name: 'StaffLeaveNoEditor',

  props: {
    staffId: Number
  },

  watch: {
    staffId (newVal, oldVal) {
      if (typeof newVal !== 'undefined') {
        this.loadLeaveInfo()
      }
    }
  },

  data () {
    return {
      columns,

      leaves: [],

      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },

      dateFormat: 'YYYY/MM/DD',

      loading: false
    }
  },

  methods: {
    loadLeaveInfo () {
      this.loading = true
      getStaffLeaveInfo({
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.leaves = res.data.leaves
        } else {
          message.error('Failed to load staff leave info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },

    showDate
  }
}
</script>

<style scoped>

</style>
