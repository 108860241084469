<template>
  <a-divider orientation="left">
    Staff list
  </a-divider>

    <a-row :gutter="[32,32]">
      <a-col :xs="24" :md="12">
          <a-input style="width: 100%" v-model:value="search" allowClear placeholder="Search in name or phone" @change="searchChange" @keyup.enter="doSearch">
              <template #suffix>
                  <SearchOutlined style="cursor: pointer;" @click="doSearch"/>
              </template>
          </a-input>
      </a-col>
      <a-col :xs="24" :md="12" style="text-align: left">
          <a-button type="primary" @click="newStaff">New Staff</a-button>
      </a-col>
    </a-row>
  <a-table :columns="columns" :data-source="staffs" :pagination="pager" rowKey="id" size="small"
           style="margin-top: 1rem;" @change="onChange">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>
    <template v-for="col in ['first_name', 'last_name']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          <a :href="`/staff/staff_info?staffId=${record.id}`" target="_blank">{{ text }}</a>
        </template>
      </div>
    </template>
    <template v-for="col in ['phone', 'email']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>

    <!--    <template #passport="{ record }">-->
    <!--      <img :src="getPictureUrl(record.passport_picture)" style="height: 1rem; width: 1rem; cursor: pointer;" @click="showPicture(record.passport_picture)">-->
    <!--    </template>-->

    <template #nation="{ record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].nation" placeholder="Nationality"
                showSearch style="width: 100%;">
        <a-select-option v-for="country in countries" v-bind:key="country.id" :value="country.name">
          {{ country.name }}
        </a-select-option>
      </a-select>
      <template v-else>
        {{ record.nation }}
      </template>
    </template>

    <template #birthday="{ record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].birthday"
            :allow-clear="false"
            style="margin: -5px 0; width: 100%"
        />
        <template v-else>
          {{
            showDate(record.birthday)
          }}
        </template>
      </div>
    </template>
    <template #join_date="{ record }">
      <a-date-picker
          v-if="editableData[record.id]"
          v-model:value="editableData[record.id].join_date"
          :allow-clear="false"
          style="margin: -5px 0; width: 100%"
      />
      <template v-else>
        {{
          showDate(record.join_date, false)
        }}
      </template>
    </template>
<!--    <template #expandedRowRender="{ record }">-->
<!--      <a-row :gutter="32" style="padding: 1rem 0">-->
<!--        <a-col>-->
<!--          More operations:-->
<!--        </a-col>-->
<!--        <a-col :xs="24" style="margin-top:1rem">-->
<!--          <a @click="newLeave(record.id)">1. Create new leave record</a>-->
<!--        </a-col>-->
<!--        <a-col :xs="24" style="margin-top:1rem">-->
<!--          <a @click="newBorrrow(record.id)">2. Create new loan record</a>-->
<!--        </a-col>-->
<!--        <a-col :xs="24" style="margin-top:1rem">-->
<!--          <a @click="renewVisa(record.id)">3. Renew visa</a>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </template>-->
    <template #operation="{ record }">
      <div class="editable-row-operations">
            <span v-if="editableData[record.id]">
              <a @click="save(record.id)">Save</a>
              <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
            </span>
        <span v-else>
          <a-space>
              <a-button @click="edit(record.id)">Edit</a-button>
              <a-button @click="resign(record)">Resign</a-button>
          </a-space>
        </span>
      </div>
    </template>
  </a-table>

  <a-modal
      title="New Staff"
      centered
      v-model:visible="visible"
      @ok="newSubmit"
      @cancel="newReset"
      okText="Save">
    <a-form ref="newStaffForm" :model="newItem" :rules="rules" :scrollToFirstError="true">
      <a-input-group compact label="Name" style="display: flex;">
        <a-form-item hasFeedback name="firstName" style="width: 50%;">
          <a-input v-model:value="newItem.firstName" placeholder="First name"/>
        </a-form-item>
        <a-form-item hasFeedback name="lastName" style="width: 50%;">
          <a-input v-model:value="newItem.lastName" name="lastName" placeholder="Last name"/>
        </a-form-item>
      </a-input-group>

      <a-input-group compact style="display: flex">
        <a-form-item hasFeedback name="nationCode" style="width: 50%;">
          <a-select :loading="fetchingCountries" v-model:value="newItem.nationCode" placeholder="Nation code" showSearch>
            <a-select-option v-for="phoneCode in phoneCodes" v-bind:key="phoneCode.id" :value="phoneCode.phone_code">
              {{ phoneCode.phone_code }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item hasFeedback name="phone" style="width: 50%;">
          <a-input v-model:value="newItem.phone" placeholder="Phone number"/>
        </a-form-item>
      </a-input-group>

      <a-form-item hasFeedback name="nationality">
        <a-select v-model:value="newItem.nationality" placeholder="Nationality" showSearch>
          <a-select-option v-for="country in countries" v-bind:key="country.id" :value="country.name">
            {{ country.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item hasFeedback name="email">
        <a-input v-model:value="newItem.email" placeholder="Email" type="text">
        </a-input>
      </a-form-item>

      <a-form-item hasFeedback name="birthday">
        <a-date-picker v-model:value="newItem.birthday" placeholder="Birthday" style="width: 100%"/>
      </a-form-item>

      <a-form-item hasFeedback name="joinDate">
        <a-date-picker v-model:value="newItem.joinDate" placeholder="Join Date" style="width: 100%"/>
      </a-form-item>

      <a-form-item hasFeedback name="referer">
        <a-select v-model:value="newItem.referrerId" placeholder="Referrer" showSearch>
          <a-select-option v-for="staff in totalStaffs" v-bind:key="staff.id" :value="staff.id">
            {{ staff.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="comment">
        <a-textarea v-model:value="newItem.comment" :auto-size="{ minRows: 5, maxRows: 10 }"
                    allow-clear placeholder="Comments, experiences, etc."/>
      </a-form-item>

    </a-form>
  </a-modal>

  <a-modal title="Staff Resign"
          centered
          v-model:visible="resignVisible"
          @ok="resignEmployee"
          @cancel="resignReset"
         :confirm-loading="resignLoading"
          okText="Resign">
      <a-form ref="resignStaffForm" :rules="resignRules" :model="resignStaffInfo" :scrollToFirstError="true">
          <a-form-item hasFeedback name="comment" required>
              <a-input v-model:value="resignStaffInfo.comment" placeholder="Reason or comments for resignation"/>
          </a-form-item>
          <a-form-item hasFeedback name="leaveDate" required>
              <a-date-picker v-model:value="resignStaffInfo.leaveDate" placeholder="Leave Date"/>
          </a-form-item>
      </a-form>
  </a-modal>
</template>

<script>

import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { createStaff, getStaffList, updateStaffInfo, resignStaff } from '../../api/staff'
import { SearchOutlined } from '@ant-design/icons-vue'
import { getCountryList } from '../../api/reer'
import { checkEmailFormat, showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    width: '10%',
    slots: { customRender: 'first_name' },
    ellipsis: true
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    width: '10%',
    slots: { customRender: 'last_name' },
    ellipsis: true
  },
  {
    title: 'Nationality',
    dataIndex: 'nation',
    width: '15%',
    slots: { customRender: 'nation' },
    ellipsis: true
  },
  {
    title: 'Age',
    dataIndex: 'birthday',
    width: '10%',
    sorter: (a, b) => a.birthday - b.birthday,
    slots: { customRender: 'birthday' }
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '17%',
    slots: { customRender: 'email' }
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    width: '15%',
    slots: { customRender: 'phone' }
  },
  {
    title: 'Join',
    dataIndex: 'join_date',
    width: '10%',
    slots: { customRender: 'join_date' },
    sorter: (a, b) => a.join_date - b.join_date,
    ellipsis: true
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '15%'
  }
]

export default {
  name: 'StaffList',

  components: {
    SearchOutlined
  },

  setup () {
    const store = useStore()

    const userId = computed(() => store.state.auth.user.id)
    const staffLoadingParams = computed(() => store.state.transition.staffLoadingParams)

    const saveCountriesInCache = ({ countries }) => store.dispatch('reer/cacheCountries', { countries })
    const cacheCountries = computed(() => store.state.reer.countries)

    const cacheStaffLoadingInfo = ({
      pager,
      filters,
      sorters,
      search
    }) => store.dispatch('transition/cacheStaffLoadingInfo', {
      pager,
      filters,
      sorters,
      search
    })

    return {
      userId,
      staffLoadingParams,
      cacheStaffLoadingInfo,
      saveCountriesInCache,
      cacheCountries
    }
  },

  mounted () {
    this.loadStaffList()
    this.loadCountries()
  },

  watch: {
    search (val) {
      this.searchTerms = {
        name: val
      }
    }
  },

  data () {
    const validateEmail = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input your email')
      } else {
        if (!checkEmailFormat(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Email should look like a@b.com')
        } else {
          return Promise.resolve()
        }
      }
    }
    return {
      columns,
      editableData: {},
      staffs: [],
      totalStaffs: [], // 仅包含id，first_name和last_name的所有员工信息，便于添加referrerId
      searchTerms: this.staffLoadingParams?.search || {},
      pager: this.staffLoadingParams?.pager || {
        pageSize: 10,
        total: 0,
        current: 1
      },
      filters: this.staffLoadingParams?.filters || {},
      sorters: this.staffLoadingParams?.sorters || [],
      countries: this.cacheCountries || [],
      search: this.searchTerms?.name || '',

      visible: false,
      fetchingCountries: false,
      phoneCodes: [],
      newItem: {
        firstName: '',
        lastName: '',
        nationality: undefined,
        birthday: undefined,
        email: '',
        nationCode: undefined,
        comment: '',
        phone: '',
        referrerId: undefined,
        joinDate: undefined
      },
      rules: {
        firstName: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        nationCode: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        nationality: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        birthday: [
          {
            required: true,
            type: 'date',
            trigger: 'change'
          }
        ],
        joinDate: [
          {
            required: true,
            type: 'date',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: 'change'
          }
        ]
      },

      resignStaff: undefined,
      resignVisible: false,
      resignRules: {
        comment: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        leaveDate: [
          {
            required: true,
            type: 'date',
            trigger: 'change'
          }
        ]
      },
      resignLoading: false,
      resignStaffInfo: {
        comment: '',
        leaveDate: undefined
      }
    }
  },

  methods: {
    loadStaffList () {
      const params = {
        pager: {
          ...this.pager,
          offset: (this.pager.current - 1) * this.pager.pageSize,
          limit: this.pager.pageSize
        },
        filters: this.filters,
        sorters: this.sorters,
        search: this.searchTerms
      }

      this.$loading.show('Loading staff list...')

      getStaffList(params).then(res => {
        if (res.code === 0) {
          this.cacheStaffLoadingInfo(params)
          this.staffs = res.data.staffs
          this.pager.total = res.data.total
          if (res.data.total_staffs && res.data.total_staffs.length > 0) {
            this.totalStaffs = res.data.total_staffs
          }
          this.totalStaffs.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
        } else {
          message.error('Failed to load staff list, please retry')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$loading.hide()
      })
    },

    loadCountries () {
      this.fetchingCountries = true
      getCountryList().then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          this.phoneCodes = res.data.countries ? res.data.countries.slice() : []
          this.phoneCodes.sort((a, b) => {
            if (a.phone_code < b.phone_code) {
              return -1
            }
            if (a.phone_code > b.phone_code) {
              return 1
            }
            return 0
          })
          this.saveCountriesInCache({ countries: this.countries })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.fetchingCountries = false
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination

      console.log('Remember transform table filters to filters')
      this.filters = {}

      console.log(tableSorters)

      this.sorters = tableSorters

      this.loadStaffList()
    },
    edit (key) {
      this.editableData[key] = cloneDeep(this.staffs.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      const params = {
        id: key,
        first_name: this.editableData[key].first_name,
        last_name: this.editableData[key].last_name,
        phone: this.editableData[key].phone,
        birthday: this.editableData[key].birthday,
        join_date: this.editableData[key].join_date,
        email: this.editableData[key].email,
        nationality: this.countries.filter(item => item.name === this.editableData[key].nation)[0].id
      }

      updateStaffInfo(params).then(res => {
        if (res.code === 0) {
          const changed = this.staffs.filter(item => key === item.id)[0]
          Object.assign(changed, this.editableData[key])
          changed.nationality = params.nationality
          delete this.editableData[key]
        } else {
          message.error('Failed to update staff info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    getPictureUrl (imgFile) {
      return '/img/passport/' + imgFile
    },

    showPicture (imgFile) {
      this.$preview(this.getPictureUrl(imgFile))
    },

    showDiffDays (date) {
      const diffDays = moment().diff(date, 'days', false)
      if (diffDays === 0) {
        return 'Today'
      } else if (diffDays === 1) {
        return 'Yesterday'
      } else {
        return `${diffDays} days ago`
      }
    },

    resign (record) {
      this.resignStaff = record
      this.resignVisible = true

      // const whastapp = phone.split(' ').join('')
      // const link = `//api.whatsapp.com/send?phone=${whastapp}`
      // window.open(link, '_blank')
    },

    resignEmployee () {
      this.resignLoading = true
      this.$refs.resignStaffForm.validate().then(() => {
        resignStaff({
          id: this.resignStaff.id,
          comment: this.resignStaffInfo.comment,
          leave_date: this.resignStaffInfo.leaveDate,
          modifier_id: this.userId
        }).then(res => {
          this.resignLoading = false
          if (res.code === 0) {
            message.success('Successfully resign staff.')
            this.loadStaffList()
          } else {
            message.error('Failed to resign staff, please retry later.')
          }
          this.resignVisible = false
          this.$refs.resignStaffForm.resetFields()
        })
      })
    },

    doSearch () {
      this.loadStaffList()
    },

    searchChange (e) {
      if (e.type === 'click') {
        this.searchTerms = {}
        this.loadStaffList()
      }
    },

    newStaff () {
      this.visible = true
    },

    newSubmit () {
      this.$refs.newStaffForm.validate().then(() => {
        const params = {
          id: this.userId,
          nationality: this.countries.filter(item => item.name === this.newItem.nationality)[0].id, // use country id instead of country name
          email: this.newItem.email,
          phone: this.newItem.nationCode + ' ' + this.newItem.phone,
          first_name: this.newItem.firstName,
          last_name: this.newItem.lastName,
          birthday: this.newItem.birthday,
          comment: this.newItem.comment,
          referrer_id: this.newItem.referrerId,
          join_date: this.newItem.joinDate
        }

        createStaff(params).then(res => {
          if (res.code === 0) {
            this.visible = false
            message.success('Successfully created new staff.')
            this.loadStaffList()
          } else {
            message.error('Failed to create new staff, please retry later.')
          }
        })
      })
    },

    newReset () {

    },

    resignReset () {
      this.resignVisible = false
      this.$refs.resignStaffForm.resetFields()
    },

    showDate
  }
}
</script>

<style scoped>

.title {
  font-weight: bold;
  color: #aaa;
  width: 15rem;
}

</style>
