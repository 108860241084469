export const LoginCode = {
  SUCCEED: 0,
  USER_NON_EXIST: 1,
  WRONG_PASSWORD: 2,
  USER_INACTIVE: 3,
  USER_LOCKED: 4,
  PASSWORD_TOO_SIMPLE: 5
}

export const RegisterCode = {
  USER_EXIST: 1,
  NEED_EMAIL_VERIFICATION: 2
}

export const LoanStatusCode = {
  NO_REPAY: 0,
  REPAID: 1
}

export const StaffStatusCode = {
  NORMAL: 0,
  RESIGN: 1
}

export const VisaStatusCode = {
  VALID: 0,
  EXPIRED: 1,
  REMOVED: 2
}

export const AttendanceStatus = {
  PRESENT: 0,
  ABSENT: 1,
  LEAVE: 2
}
