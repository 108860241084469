<template>
  <div class="main" style="padding: 1rem; text-align: center">
    <div class="content">
      <span v-if="code===-1">
        You are not supposed to be here, please visit <router-link to="/">Home</router-link> for more information.
      </span>
      <span v-if="code==='0'">
        Your email has been successfully confirmed, please <router-link to="/user/login">Login</router-link>.
      </span>
      <span v-if="code==='1'">
        System busy, please retry to <router-link to="/user/login">Login</router-link> later.
      </span>
      <span v-if="code==='2'">
        Confirm link has expired, please <router-link to="/user/login">Login</router-link> to get confirmation again.
      </span>
      <span v-if="code==='3'">
        Your email has already been confirmed, please <router-link to="/user/login">Login</router-link>.
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyResult',

  data () {
    const code = this.$route.query.code || -1
    return {
      code
    }
  }
}
</script>

<style scoped>

.content {
  margin-top: 3rem;
  font-weight: bold;
}

</style>
