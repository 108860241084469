<template>
  <div style="max-width: 420px; margin: 2rem auto 0 auto; display: flex; justify-content: center; text-align: left;">
    <a-steps :current="current" size="small">
      <a-step title="1. Basic Information"/>
      <a-step title="2. Skills & Passport"/>
      <a-step title="3. Done"/>
    </a-steps>
  </div>
  <div style="max-width: 420px; margin: 1rem auto; background: white; padding: 1rem;">
    <div v-if="current === 0" style="text-align: left;">
      <a-form ref="applyForm" :model="form" :rules="rules" :scrollToFirstError="true">
        <a-input-group compact label="Name" style="display: flex;">
          <a-form-item hasFeedback name="firstName" style="width: 50%;">
            <a-input v-model:value="form.firstName" placeholder="First name"/>
          </a-form-item>
          <a-form-item hasFeedback name="lastName" style="width: 50%;">
            <a-input v-model:value="form.lastName" name="lastName" placeholder="Last name"/>
          </a-form-item>
        </a-input-group>

        <a-input-group compact style="display: flex">
          <a-form-item hasFeedback name="nationCode" style="width: 50%;">
            <a-select :loading="fetchingCountries" v-model:value="form.nationCode" placeholder="Nation code" showSearch>
              <a-select-option v-for="phoneCode in phoneCodes" v-bind:key="phoneCode.id" :value="phoneCode.phone_code">
                {{ phoneCode.phone_code }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item hasFeedback name="phone" style="width: 50%;">
            <a-input v-model:value="form.phone" placeholder="Phone number"/>
          </a-form-item>
        </a-input-group>

        <a-form-item hasFeedback name="nationality">
          <a-select v-model:value="form.nationality" placeholder="Nationality" showSearch>
            <a-select-option v-for="country in countries" v-bind:key="country.id" :value="country.name">
              {{ country.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item hasFeedback name="email">
          <a-input v-model:value="form.email" placeholder="Email" type="text">
          </a-input>
        </a-form-item>

        <a-form-item hasFeedback name="birthday">
          <a-date-picker v-model:value="form.birthday" placeholder="Birthday" style="width: 100%"/>
        </a-form-item>

        <a-form-item hasFeedback name="referer">
          <a-input v-model:value="form.referer" placeholder="Optional referer name" type="text">
          </a-input>
        </a-form-item>

        <a-form-item>
          <a-button class="bold" html-type="submit" style="width: 100%" type="primary" @click="onSubmit">
            Save & Next
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="current === 1" style="text-align: left">
      <a-form ref="applyForm2" :model="form2" :rules="rules2" :scrollToFirstError="true">
        <a-form-item name="skills">
          <a-textarea v-model:value="form2.skills" :auto-size="{ minRows: 5, maxRows: 10 }"
                      allow-clear placeholder="Brief of skills and experience"/>
        </a-form-item>

        <a-upload-dragger
            v-model:fileList="fileList"
            :multiple="false"
            :action="uploadUrl"
            name="file"
            @change="handleChange"
        >
          <p class="ant-upload-drag-icon">
            <UploadOutlined/>
          </p>
          <p>Click to upload passport picture</p>
        </a-upload-dragger>

        <a-form-item style="margin-top: 2rem;">
          <a-button class="bold" type="default" @click="current=0">
            Prev
          </a-button>

          <a-button class="bold" html-type="submit" style="margin-left: 1rem;" type="primary" @click="onSubmit2">
            Submit
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <div v-if="current === 2" style="text-align: center;">
      <a-row>
        <a-col>
          <p style="font-size: 1.2rem; font-weight: bold;">Registration Complete!</p>
          <p>
            Thank you for your registration. <br><br>HR of ReerGlobal will contact you for interview if passed. <br>
          </p>
          <p>
            Please feel free to write to <a href="joy.wu@reerglboal.com">joy.wu@reerglobal.com</a> for further information.
          </p>
        </a-col>
      </a-row>
    </div>
  </div>

</template>

<script>
import { checkEmailFormat } from '../../utils/util'
import { applyTwo, applyOne } from '../../api/user'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { getCountryList } from '../../api/reer'
import { computed } from 'vue'

import { UploadOutlined } from '@ant-design/icons-vue'

export default {
  name: 'Apply',

  components: {
    UploadOutlined
  },

  setup () {
    const store = useStore()
    const saveCountriesInCache = ({ countries }) => store.dispatch('reer/cacheCountries', { countries })
    const cacheCountries = computed(() => store.state.reer.countries)

    const cacheCandidateInfo = (user) => store.dispatch('transition/cacheCandidateInfo', user)
    const candidateInfo = computed(() => store.state.transition.candidateInfo)

    const removeCandidateInfo = () => store.dispatch('transition/removeCandidateInfo')

    return {
      cacheCountries,
      saveCountriesInCache,
      candidateInfo,
      cacheCandidateInfo,
      removeCandidateInfo
    }
  },

  data () {
    const validateEmail = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input your email')
      } else {
        if (!checkEmailFormat(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Email should look like a@b.com')
        } else {
          return Promise.resolve()
        }
      }
    }

    return {
      countries: this.cacheCountries || [],
      phoneCodes: [],
      form: {
        nationCode: this.user?.phone ? this.user.phone.split(' ')[0] : undefined,
        nationality: this.user?.nationality ? this.countries.filter(item => item.id === this.user.nationality)[0].name : undefined,
        firstName: this.user?.first_name || '',
        lastName: this.user?.last_name || '',
        phone: this.user?.phone ? this.user.phone.split(' ')[1] : '',
        birthday: this.user?.birthday,
        referer: this.user?.referer_name || '',
        email: this.user?.email || ''
      },

      form2: {
        skills: ''
      },

      rules: {
        firstName: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        nationCode: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        nationality: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            trigger: 'change'
          }
        ],
        birthday: [
          {
            required: true,
            type: 'date',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: 'change'
          }
        ]
      },

      rules2: {
        skills: [
          {
            required: true,
            trigger: 'change'
          }
        ]
      },

      current: 0,

      fileList: [],

      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'user/upload',

      user: this.candidateInfo || {},

      fileName: '',

      fetchingCountries: false
    }
  },

  mounted () {
    this.loadCountries()
  },

  methods: {
    onSubmit () {
      this.$refs.applyForm.validate().then(() => {
        applyOne({
          id: this.user?.id,
          nationality: this.countries.filter(item => item.name === this.form.nationality)[0].id, // use country id instead of country name
          email: this.form.email,
          phone: this.form.nationCode + ' ' + this.form.phone,
          first_name: this.form.firstName,
          last_name: this.form.lastName,
          skills: this.form.skills,
          birthday: this.form.birthday,
          referer: this.form.referer
        }).then(res => {
          if (res.code === 0) {
            message.info('Basic information saved, please continue')
            this.user = res.data.user
            this.cacheCandidateInfo(this.user)
            this.current = 1
          } else if (res.code === 1) {
            message.error('Email is used, please try again')
          } else if (res.code === 2) {
            message.error('Phone is used, please try again')
          } else {
            message.error('Failed to submit, please try again')
          }
        })
      })
    },
    onSubmit2 () {
      this.$refs.applyForm2.validate().then(() => {
        if (this.fileList.length === 0) {
          message.warn('Please upload passport picture')
        } else {
          if (this.user && this.user.id) {
            applyTwo({
              id: this.user.id,
              skills: this.form2.skills,
              file: this.fileName
            }).then(res => {
              if (res.code === 0) {
                this.current = 2
                this.removeCandidateInfo()
              } else {
                message.error('Failed to save skills and passport info, please try again')
                this.fileList = []
                this.fileName = ''
              }
            })
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    loadCountries () {
      this.fetchingCountries = true
      getCountryList().then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          this.phoneCodes = res.data.countries ? res.data.countries.slice() : []
          this.phoneCodes.sort((a, b) => {
            if (a.phone_code < b.phone_code) {
              return -1
            }
            if (a.phone_code > b.phone_code) {
              return 1
            }
            return 0
          })
          this.saveCountriesInCache({ countries: this.countries })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.fetchingCountries = false
      })
    },
    handleChange (info) {
      const status = info.file.status

      // if (status !== 'uploading') {
      //   console.log(info.file, info.fileList)
      // }

      if (status === 'done') {
        this.fileName = info.file.response?.name
        if (!this.fileName || this.fileName.length === 0) {
          message.error(`Faied to upload ${info.file.name} due to server problem, please try later`)
        } else {
        }
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    }
  }
}
</script>

<style scoped>

</style>
