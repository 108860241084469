<template>
  <a-space size="large" style="margin: 1rem 0;">
    <a-button type="primary" @click="create">New Visa</a-button>
  </a-space>
  <a-table :loading="loading" :columns="columns" :data-source="visas" size="small">
    <template #index="{ index }">
      {{ pager.pageSize * (pager.current - 1) + index + 1 }}
    </template>

    <template #visa_type="{ text, record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].visa_type" placeholder="Leave type"
                 style="width: 100%;">
        <a-select-option v-for="visa_type in visa_types" v-bind:key="visa_type.id" :value="visa_type.code_name">
          {{ visa_type.code_name }}
        </a-select-option>
      </a-select>
      <template v-else>
        {{ text }}
      </template>
    </template>

    <template #country="{ text, record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].country" placeholder="Visa country"
                style="width: 100%;">
        <a-select-option v-for="country in countries" v-bind:key="country.id" :value="country.name">
          {{ country.name }}
        </a-select-option>
      </a-select>
      <template v-else>
        {{ text }}
      </template>
    </template>

    <template #company="{ text, record }">
      <a-select v-if="editableData[record.id]" v-model:value="editableData[record.id].company" placeholder="Company"
                 style="width: 100%;">
        <a-select-option v-for="company in companies" v-bind:key="company.id" :value="company.code_name">
          {{ company.code_name }}
        </a-select-option>
      </a-select>
      <template v-else>
        {{ text }}
      </template>
    </template>

    <template v-for="col in ['comment']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          <a-tooltip
              placement="left"
              :title="text"
              :auto-adjust-overflow="false"
          >
          <span>{{ text }}</span>
          </a-tooltip>
        </template>
      </div>
    </template>

    <template v-for="col in ['amount', 'company_pay', 'staff_pay']" :key="col" #[col]="{ text, record }">
      <div>
        <a-input-number
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id][col]"
            style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </div>
    </template>
    <template #start="{ record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].start"
            :allow-clear="false"
            style="margin: -5px 0; width: 100%"
        />
        <template v-else>
          {{
            showDate(record.start, false)
          }}
        </template>
      </div>
    </template>
    <template #due="{ record }">
      <div>
        <a-date-picker
            v-if="editableData[record.id]"
            v-model:value="editableData[record.id].due"
            :allow-clear="false"
            style="margin: -5px 0; width: 100%"
        />
        <template v-else>
          {{
            showDate(record.due, false)
          }}
        </template>
      </div>
    </template>
    <template v-for="col in ['create_at']" :key="col" #[col]="{ text }">
      {{ showDate(text, false) }}
    </template>
    <template #operation="{ record }">
      <div class="editable-row-operations">
            <span v-if="editableData[record.id]">
              <a @click="save(record.id)">Save</a>
              <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
            </span>
        <span v-else>
          <a-space>
              <a-button @click="edit(record.id)">Edit</a-button>
              <a-popconfirm
                  title="Are you sure to expire this visa?"
                  @confirm="expireVisa(record.id)">
                <a-button>Expire</a-button>
              </a-popconfirm>
              <a-popconfirm
                  title="Are you sure to delete this visa?"
                  @confirm="delVisa(record.id)"
              >
                <a-button>Remove</a-button>
              </a-popconfirm>
            </a-space>
        </span>
      </div>
    </template>

    <template #expandedRowRender="{ record }">
      <a-row>
        <a-col :xs="24" v-if="record.staff_pay > 0">
          <InstallmentEditor :data="record.installment" :bizId="record.id" :loanId="record.loan_id" @saveInstallment="saveInstallment"></InstallmentEditor>
        </a-col>
      </a-row>
    </template>

  </a-table>

  <a-modal
      title="New Visa"
      centered
      v-model:visible="visible"
      @ok="newSubmit"
      @cancel="newReset"
      okText="Save"
  >
    <a-form :rules="rules" :model="newItem" ref="newForm">
      <a-form-item name="visa_type" hasFeedback required>
        <a-select v-model:value="newItem.visa_type" placeholder="Visa type" showSearch style="width: 100%;">
          <a-select-option v-for="visa_type in visa_types" v-bind:key="visa_type.id" :value="visa_type.id">
            {{ visa_type.code_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="country" hasFeedback required>
        <a-select v-model:value="newItem.country" placeholder="Visa of which country" showSearch style="width: 100%;">
          <a-select-option v-for="country in countries" v-bind:key="country.id" :value="country.name">
            {{ country.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="company" hasFeedback required>
        <a-select v-model:value="newItem.company" placeholder="Visa of company" showSearch style="width: 100%;">
          <a-select-option v-for="company in companies" v-bind:key="company.id" :value="company.id">
            {{ company.code_name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item name="amount" hasFeedback required>
        <a-input-number
            placeholder="Total amount"
            v-model:value="newItem.amount"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="company_pay" hasFeedback required>
        <a-input-number
            placeholder="Company pay"
            v-model:value="newItem.company_pay"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="staff_pay" hasFeedback required>
        <a-input-number
            placeholder="Staff pay"
            v-model:value="newItem.staff_pay"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="range" required>
        <a-range-picker
            :placeholder="['Start Date', 'End Date']"
            format="YYYY-MM-DD"
            v-model:value="newItem.range"
            style="width: 100%;"
        />
      </a-form-item>

      <a-form-item name="comment" hasFeedback>
        <a-textarea
            placeholder="Comments"
            v-model:value="newItem.comment"
            allowClear
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

import { cloneDeep } from 'lodash-es'
import { updateStaffVisaInfo, createStaffVisaInfo, getStaffVisaInfo, updateStaffVisaInstallment } from '../../api/staff'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import InstallmentEditor from './InstallmentEditor'
import { showDate } from '../../utils/util'
import { VisaStatusCode } from '../../api/codes'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Type',
    dataIndex: 'visa_type',
    width: '7%',
    slots: { customRender: 'visa_type' },
    ellipsis: true
  },
  {
    title: 'Country',
    dataIndex: 'country',
    width: '10%',
    slots: { customRender: 'country' },
    ellipsis: true
  },
  {
    title: 'Company',
    dataIndex: 'company',
    width: '5%',
    slots: { customRender: 'company' },
    ellipsis: true
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    width: '5%',
    slots: { customRender: 'amount' },
    ellipsis: true
  },
  {
    title: 'Company Pay',
    dataIndex: 'company_pay',
    width: '10%',
    slots: { customRender: 'company_pay' },
    ellipsis: true
  },
  {
    title: 'Staff Pay',
    dataIndex: 'staff_pay',
    width: '10%',
    slots: { customRender: 'staff_pay' },
    ellipsis: true
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    ellipsis: true
  },
  {
    title: 'Due',
    dataIndex: 'due',
    width: '10%',
    slots: { customRender: 'due' },
    ellipsis: true
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    width: '10%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Op',
    dataIndex: 'operation',
    width: '18%',
    slots: { customRender: 'operation' }
  }
]

export default {
  name: 'StaffVisa',
  components: { InstallmentEditor },
  props: {
    visa_types: {
      type: Array,
      default: () => []
    },

    companies: {
      type: Array,
      default: () => []
    },

    countries: {
      type: Array,
      default: () => []
    }
  },

  setup () {
    const route = useRoute()
    const store = useStore()
    const staffId = route.query.staffId || undefined
    const user = computed(() => store.state.auth.user)

    return {
      staffId,
      user
    }
  },

  created () {
    this.loadVisaInfo()
  },

  data () {
    return {
      columns,

      visas: [],

      editableData: [],

      pager: {
        pageSize: 10,
        total: 0,
        current: 1
      },

      dateFormat: 'YYYY/MM/DD',

      newItem: {
        range: [],
        company: undefined,
        country: undefined,
        comment: undefined,
        amount: undefined,
        company_pay: undefined,
        staff_pay: undefined,
        visa_type: undefined
      },

      rules: {
        visa_type: [
          {
            required: true,
            type: 'number'
          }
        ],
        country: [
          {
            required: true
          }
        ],
        range: [
          {
            required: true,
            type: 'array'
          }
        ],
        company: [
          {
            required: true,
            type: 'number'
          }
        ],
        amount: [
          {
            required: true,
            type: 'number'
          }
        ],
        company_pay: [
          {
            required: true,
            type: 'number'
          }
        ],
        staff_pay: [
          {
            required: true,
            type: 'number'
          }
        ]
      },

      loading: false,
      visible: false
    }
  },

  methods: {
    loadVisaInfo () {
      this.loading = true
      getStaffVisaInfo({
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.visas = res.data.visas
        } else {
          message.error('Failed to load staff visa info, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      }).finally(() => {
        this.loading = false
      })
    },

    create () {
      this.visible = true
    },

    edit (key) {
      this.editableData[key] = cloneDeep(this.visas.filter(item => item.id === key)[0])
    },
    cancel (key) {
      delete this.editableData[key]
    },
    save (key) {
      const params = {
        id: key,
        visa_type_id: this.visa_types.filter(item => item.code_name === this.editableData[key].visa_type)[0].id,
        country_id: this.countries.filter(item => item.name === this.editableData[key].country)[0].id,
        company_id: this.companies.filter(item => item.code_name === this.editableData[key].company)[0].id,
        amount: this.editableData[key].amount,
        comment: this.editableData[key].comment,
        company_pay: this.editableData[key].company_pay,
        staff_pay: this.editableData[key].staff_pay,
        start: this.editableData[key].start,
        due: this.editableData[key].due,
        modifier_id: this.user.id
      }

      updateStaffVisaInfo(params).then(res => {
        if (res.code === 0) {
          const changed = this.visas.filter(item => key === item.id)[0]
          Object.assign(changed, this.editableData[key])
          delete this.editableData[key]
          message.success('Successfully update staff visa info')
        } else {
          message.error('Failed to update staff visa info, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    expireVisa (key) {
      const params = {
        id: key,
        modifier_id: this.user.id,
        status: VisaStatusCode.EXPIRED
      }
      updateStaffVisaInfo(params).then(res => {
        if (res.code === 0) {
          this.loadVisaInfo()
          message.success('Successfully expire staff visa')
        } else {
          message.error('Failed to expire staff visa, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    delVisa (key) {
      const params = {
        id: key,
        modifier_id: this.user.id,
        status: VisaStatusCode.REMOVED
      }
      updateStaffVisaInfo(params).then(res => {
        if (res.code === 0) {
          this.loadVisaInfo()
          message.success('Successfully remove staff visa')
        } else {
          message.error('Failed to remove staff visa, please retry')
        }
      }).catch(err => {
        console.log(err)
      })
    },

    newSubmit () {
      this.$refs.newForm.validate().then(() => {
        if (this.newItem.amount !== (this.newItem.company_pay + this.newItem.staff_pay)) {
          message.error('Total amount doesn\'t equal to the addition of company pay and staff pay.')
          return
        }

        createStaffVisaInfo({
          staff_id: this.staffId,
          visa_type_id: this.newItem.visa_type,
          country_id: this.countries.filter(item => item.name === this.newItem.country)[0].id,
          company_id: this.newItem.company,
          amount: this.newItem.amount,
          company_pay: this.newItem.company_pay,
          staff_pay: this.newItem.staff_pay,
          comment: this.newItem.comment,
          start: this.newItem.range[0],
          due: this.newItem.range[1],
          creator_id: this.user.id,
          approver_id: this.user.id
        }).then(res => {
          if (res.code === 0) {
            message.success('Successfully created new visa.')
            this.visible = false
            setTimeout(() => {
              this.loadVisaInfo()
            }, 500)
          } else {
            message.success('Failed to create new visa, please try again later')
          }
        }).catch(err => {
          message.error('Probably network broken, please try again later')
          console.log(err)
        })
      })
    },

    newReset () {
      this.visible = false
      this.$refs.newForm.resetFields()
    },

    saveInstallment (loanId, total, installments, bizId) {
      // 如果loanId为空，visaId不为空，则表示新增分期
      const params = {
        id: loanId,
        visa_id: bizId,
        staff_id: this.staffId,
        purpose: 'Visa Installment',
        staff_pay: total,
        installment: installments,
        modifier_id: this.user.id
      }
      updateStaffVisaInstallment(params).then(res => {
        if (res.code === 0) {
          message.success('Visa loan installments have been saved')
          this.$router.push({
            path: '/staff/staff_info',
            query: {
              staffId: this.staffId,
              page: 'visa'
            }
          })
        } else {
          message.error('Failed to save visa loan installments, please retry')
        }
      }).catch(err => {
        message.error('Probably network broken, please try again later')
        console.log(err)
      })
    },

    showDate
  }
}
</script>

<style scoped>

</style>
