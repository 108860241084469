import { createLogger, createStore } from 'vuex'

import auth from './modules/auth'
import transition from './modules/transition'
import reer from './modules/reer'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    auth,
    transition,
    reer
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
}
)
