const state = {
  spuLoadingParams: localStorage.getItem('SPU_LOADING_PARAMS')
    ? JSON.parse(localStorage.getItem('SPU_LOADING_PARAMS')) || {}
    : {},

  candidateInfo: localStorage.getItem('CANDIDATE_INFO')
    ? JSON.parse(localStorage.getItem('CANDIDATE_INFO')) || {}
    : {},

  candidateLoadingInfo: localStorage.getItem('CANDIDATE_LOADING_INFO')
    ? JSON.parse(localStorage.getItem('CANDIDATE_LOADING_INFO')) || {}
    : {},

  staffInfo: localStorage.getItem('STAFF_INFO')
    ? JSON.parse(localStorage.getItem('STAFF_INFO')) || {}
    : {},

  staffLoadingInfo: localStorage.getItem('STAFF_LOADING_INFO')
    ? JSON.parse(localStorage.getItem('STAFF_LOADING_INFO')) || {}
    : {}
}

const mutations = {
  SET_SPU_LOADING_PARAMS: (state, info) => {
    state.spuLoadingParams = info
  },

  SET_CANDIDATE_INFO: (state, user) => {
    state.candidateInfo = user
  },

  SET_CANDIDATE_LOADING_INFO: (state, info) => {
    state.candidateLoadingInfo = info
  },

  SET_STAFF_INFO: (state, staff) => {
    state.staffInfo = staff
  },

  SET_STAFF_LOADING_INFO: (state, info) => {
    state.staffLoadingInfo = info
  }
}

const actions = {
  cacheSpuLoadingParams ({ commit }, {
    pager,
    filters,
    sorters,
    search
  }) {
    localStorage.setItem('SPU_LOADING_PARAMS', JSON.stringify({
      pager,
      filters,
      sorters,
      search
    }))
    commit('SET_SPU_LOADING_PARAMS', {
      pager,
      filters,
      sorters,
      search
    })
  },

  cacheCandidateInfo ({ commit }, user) {
    localStorage.setItem('CANDIDATE_INFO', JSON.stringify(user))
    commit('SET_CANDIDATE_INFO', user)
  },

  removeCandidateInfo ({ commit }) {
    localStorage.removeItem('CANDIDATE_INFO')
    commit('SET_CANDIDATE_INFO', {})
  },

  cacheCandidateLoadingInfo ({ commit }, info) {
    localStorage.setItem('CANDIDATE_LOADING_INFO', JSON.stringify(info))
    commit('SET_CANDIDATE_LOADING_INFO', info)
  },

  cacheStaffInfo ({ commit }, staff) {
    localStorage.setItem('STAFF_INFO', JSON.stringify(staff))
    commit('SET_STAFF_INFO', staff)
  },

  removeStaffInfo ({ commit }) {
    localStorage.removeItem('STAFF_INFO')
    commit('SET_STAFF_INFO', {})
  },

  cacheStaffLoadingInfo ({ commit }, info) {
    localStorage.setItem('STAFF_LOADING_INFO', JSON.stringify(info))
    commit('SET_STAFF_LOADING_INFO', info)
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
