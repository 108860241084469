// loading.js
let htmlElement

const htmlString = `
  <div style="
    background: black;
    opacity: 0.6;
    z-index: 10000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    ">
    <div style="text-align: center; color: white">
      <div class="ring"></div>
      <div style="margin-top: 1rem;" id="$$$content$$$"></div>
    </div>
  </div>
`

export default {
  install: (app) => {
    const loading = {
      show (content = 'Loading...') {
        if (!htmlElement) {
          const _doc = new DOMParser().parseFromString(htmlString, 'text/html')
          htmlElement = _doc.body.firstChild
          document.body.appendChild(htmlElement)
          _doc.close()
        }
        document.getElementById('$$$content$$$').innerText = content
        htmlElement.style.display = 'flex'
      },
      hide () {
        if (htmlElement) {
          htmlElement.style.display = 'none'
          // htmlElement.remove()
          // document.body.removeChild(htmlElement)
          // htmlElement = undefined
        }
      }
    }

    if (!app.$loading) {
      app.$loading = loading
    }

    app.config.globalProperties.$loading = app.$loading
  }
}
