<template>
    <a-tabs v-model:activeKey="activeKey" tab-position="left">
        <a-tab-pane key="1" tab="Password">
            <a-divider orientation="left">
                Change your password
            </a-divider>
            <a-form
                    ref="pwdForm"
                    :model="form"
                    :rules="rules"
                    :scrollToFirstError="true">

                <a-form-item hasFeedback name="current">
                    <a-input v-model:value="form.current" placeholder="Current password" type="password"/>
                </a-form-item>
                <a-form-item hasFeedback name="password">
                    <a-input v-model:value="form.password" placeholder="New password" type="password"/>
                </a-form-item>
                <a-form-item hasFeedback name="password2">
                    <a-input v-model:value="form.password2" placeholder="New password again" type="password"/>
                </a-form-item>
                <a-form-item>
                    <a-button html-type="submit" type="primary" class="bold" style="width: 100%" @click="onSubmit">
                        Change Password
                    </a-button>
                </a-form-item>
            </a-form>
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import * as md5 from 'md5'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { changePassword } from '../../api/user'
import { LoginCode } from '../../api/codes'

export default {
  name: 'Settings',

  setup () {
    const store = useStore()

    const user = computed(() => store.state.auth.user)
    const logout = () => store.dispatch('auth/logout')

    return {
      user,
      logout
    }
  },

  data () {
    const validatePass = async (rule, value) => {
      if (value === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input the new password')
      } else {
        if (this.form.password !== '') {
          if (this.form.password === this.form.current) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Password must be different from current one')
          } else if (this.form.password.length < 6) {
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject('Password must be at least 6 characters')
          }
        }

        return Promise.resolve()
      }
    }

    const validatePass2 = async (rule, value) => {
      if (value === '') {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input the new password again')
      } else if (value !== this.form.password) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Two passwords don't match!")
      } else {
        return Promise.resolve()
      }
    }

    return {
      activeKey: '1',
      form: {
        current: '',
        password: '',
        password2: ''
      },
      rules: {
        current: [
          {
            required: true,
            message: 'Please input your password',
            trigger: 'change'
          },
          {
            min: 6,
            message: 'Must be at least 6 characters',
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            validator: validatePass,
            trigger: 'change'
          }
        ],
        password2: [
          {
            required: true,
            validator: validatePass2,
            trigger: 'change'
          }
        ]
      }
    }
  },

  methods: {
    onSubmit () {
      this.$refs.pwdForm
        .validate()
        .then(() => {
          changePassword({
            current: md5(this.form.current),
            password: md5(this.form.password)
          })
            .then(res => {
              if (res.code === 0) {
                message.success('Successfully changed password, redirecting to login...')

                this.logout().then(() => {
                  this.$router.push({ path: '/user/login' })
                })
              } else if (res.code === LoginCode.USER_NON_EXIST) {
                message.error('Something wrong with your user id, please relogin and change password again')
                this.logout().then(() => {
                  this.$router.push({ path: '/user/login' })
                })
              } else if (res.code === LoginCode.WRONG_PASSWORD) {
                message.error('Wrong password, please check your current password')
              } else if (res.code === LoginCode.PASSWORD_TOO_SIMPLE) {
                message.error('Your new password is too simple, please select another one')
              }
            })
            .catch(err => {
              console.log(err)
              message.error('Failed to change password, please retry later')
            })
        })
    }
  }
}
</script>

<style scoped>

</style>
