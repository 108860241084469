<template>
    <a-upload
            v-show="!imgUrl"
            v-model:file-list="fileList"
            name="avatar"
            list-type="picture-card"
            :multiple="false"
            :show-upload-list="false"
            :action="actionUrl"
            :before-upload="beforeUpload"
            @change="handleChange"
    >
        <div ref="avatarUploader" style="width: 200px; height: 200px; display: flex; align-items: center; justify-content: center">
            <div>
                <loading-outlined v-if="loading"></loading-outlined>
                <plus-outlined v-else></plus-outlined>
                <div class="ant-upload-text">Upload</div>
            </div>
        </div>
    </a-upload>
    <div v-if="imgUrl" style="width: 200px; height: 200px;position: relative; margin: 15px;">
        <a-image
                :width="200"
                :height="200"
                :src="imageUrl ? imageUrl : imgUrl"
        />
        <span class="change-pic" @click="$refs.avatarUploader.click"><EditOutlined /></span>
    </div>
</template>

<script>

import { PlusOutlined, LoadingOutlined, EditOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
// import { ref } from 'vue'

import { updateStaffInfo } from '../../api/staff'

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'StaffAvatar',
  components: {
    LoadingOutlined,
    PlusOutlined,
    EditOutlined
  },

  props: {
    bizId: {
      type: Number,
      default: 0,
      required: true
    },
    imgUrl: {
      type: String,
      default: '',
      required: true
    }
  },

  // setup (props) {
  //   const fileList = ref([])
  //   const loading = ref(false)
  //   const fileName = ref('')
  //   const actionUrl = process.env.VUE_APP_API_BASE_URL + 'user/avatar'
  //   const tempImgUrl = ref()
  //
  //   const getPictureUrl = (imgFile) => {
  //     if (imgFile) {
  //       return '/img/avatar/' + imgFile
  //     } else {
  //       return undefined
  //     }
  //   }
  //
  //   console.log(props.imgUrl)
  //
  //   const imageUrl = getPictureUrl(tempImgUrl.value)
  //
  //   console.log(imageUrl.value)
  //
  //   const updateAvatar = (bizId, avatar) => {
  //     updateStaffInfo({
  //       id: bizId,
  //       avatar: avatar
  //     }).then(res => {
  //       if (res.code === 0) {
  //         message.success('Avatar successfully uploaded')
  //       } else {
  //         message.error('Failed to upload avatar, please retry later')
  //       }
  //     })
  //   }
  //
  //   const handleChange = info => {
  //     if (info.file.status === 'uploading') {
  //       loading.value = true
  //       return
  //     }
  //
  //     if (info.file.status === 'done') {
  //       fileName.value = info.file.response?.name
  //       // Get this url from response in real world.
  //       getBase64(info.file.originFileObj, base64Url => {
  //         imageUrl.value = base64Url
  //         loading.value = false
  //         updateAvatar(props.bizId, fileName.value)
  //       })
  //     }
  //
  //     if (info.file.status === 'error') {
  //       loading.value = false
  //       message.error('upload error')
  //     }
  //   }
  //
  //   const beforeUpload = file => {
  //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
  //     if (!isJpgOrPng) {
  //       message.error('You can only upload JPG file!')
  //     }
  //
  //     const isLt5M = file.size / 1024 / 1024 < 5
  //
  //     if (!isLt5M) {
  //       message.error('Image must smaller than 5MB!')
  //     }
  //
  //     return isJpgOrPng && isLt5M
  //   }
  //
  //   return {
  //     fileList,
  //     loading,
  //     imageUrl,
  //     actionUrl,
  //     fileName,
  //     handleChange,
  //     beforeUpload
  //   }
  // }

  data () {
    return {
      imageUrl: '',
      fileList: [],
      loading: false,
      actionUrl: process.env.VUE_APP_API_BASE_URL + 'user/avatar'
    }
  },

  methods: {
    updateAvatar (bizId, avatar) {
      updateStaffInfo({
        id: bizId,
        avatar: avatar
      }).then(res => {
        if (res.code === 0) {
          message.success('Avatar successfully uploaded')
        } else {
          message.error('Failed to upload avatar, please retry later')
        }
      })
    },

    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }

      if (info.file.status === 'done') {
        this.fileName = info.file.response?.name
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, base64Url => {
          this.imageUrl = base64Url
          this.loading = false
          this.updateAvatar(this.bizId, this.fileName)
        })
      }

      if (info.file.status === 'error') {
        this.loading = false
        message.error('upload error')
      }
    },

    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG file!')
      }

      const isLt5M = file.size / 1024 / 1024 < 5

      if (!isLt5M) {
        message.error('Image must smaller than 5MB!')
      }

      return isJpgOrPng && isLt5M
    }
  }
}
</script>

<style scoped>

.change-pic {
    position: absolute;
    background: #d46b08;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    right: -15px;
    top: -15px;
    text-align: center;
}

</style>
