<template>
  <div class="main">
    <a-form
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :scrollToFirstError="true">

      <a-form-item hasFeedback name="email">
        <a-input v-model:value="form.email" placeholder="Email" type="text">
          <template #prefix>
            <UserOutlined style="color:rgba(0,0,0,.25)"/>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item hasFeedback name="password">
        <a-input v-model:value="form.password" placeholder="Password" type="password">
          <template #prefix>
            <LockOutlined style="color:rgba(0,0,0,.25)"/>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button html-type="submit" type="primary" class="bold" style="width: 100%" @click="onSubmit">
          Login
        </a-button>
      </a-form-item>

      <a-form-item style="text-align: center">
        <router-link class="bold" to="/user/register">Register a new account</router-link>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>

import { Button, Form, Input, message } from 'ant-design-vue'
import { checkEmailFormat } from '@/utils/util'
import { LockOutlined, UserOutlined } from '@ant-design/icons-vue'

import { LoginCode } from '../../api/codes'

import { login } from '@/api/user'

import * as md5 from 'md5'
import { useStore } from 'vuex'

export default {
  name: 'Login',
  components: {
    [Form.name]: Form,
    [Form.Item.name]: Form.Item,
    [Input.name]: Input,
    [Button.name]: Button,
    UserOutlined,
    LockOutlined
  },

  setup () {
    const store = useStore()

    const loginSucceed = ({
      token,
      logged,
      user
    }) => store.dispatch('auth/loginSucceed', {
      token,
      logged,
      user
    })

    return {
      loginSucceed
    }
  },

  data () {
    const validateEmail = async (rule, value) => {
      if (!value) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Please input your email')
      } else {
        if (!checkEmailFormat(value)) {
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('Email should look like a@b.com')
        } else {
          return Promise.resolve()
        }
      }
    }

    return {
      wrapperCol: {
        span: 12,
        offset: 6
      },
      form: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          {
            required: true,
            validator: validateEmail,
            trigger: 'change'
          }
        ],
        password: [
          {
            required: true,
            message: 'Please input your password',
            trigger: 'change'
          },
          {
            min: 6,
            message: 'Must be at least 6 characters',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    onSubmit () {
      this.$refs.ruleForm
        .validate()
        .then(() => {
          this.$loading.show('Signing into ReerGlobal...')
          login({
            email: this.form.email,
            password: md5(this.form.password)
          })
            .then(res => {
              if (res.code === LoginCode.SUCCEED) {
                this.loginSucceed({
                  token: res.data.token,
                  logged: true,
                  user: res.data.user
                })
                setTimeout(() => {
                  this.$loading.hide()
                  message.success(res.message)
                  this.$router.push({ path: this.$route.query.redirect || '/' })
                }, 1000)
              } else {
                message.error(res.message)
                this.$loading.hide()
              }
            })
            .catch(err => {
              console.log(err)
              this.$loading.hide()
            })
        })
        .catch(err => {
          console.log('error', err)
        })
    }
  }
}
</script>

<style scoped>

.bold {
  font-weight: bold;
}

</style>
