<template>
  <div v-if="passport">
  <a-space>
    <a-card title="Passport" style="width: 12rem;">
      <SingleFileUpload v-if="ppEdit" @fileUploaded="passportUploaded"></SingleFileUpload>
      <img v-else :src="getPictureUrl(passport.passport_picture)" style="width: 10rem;height: 10rem;"
           @click="showPicture(passport.passport_picture)">
      <template #extra>
        <a href="#" v-if="!ppEdit"><EditOutlined @click="edit(0)"/></a>
        <a href="#" v-else><UndoOutlined @click="cancel(0)"/></a>
      </template>
    </a-card>
    <a-card title="Holding Passport" style="width: 12rem;">
      <SingleFileUpload v-if="hppEdit" @fileUploaded="holdingPassportUploaded"></SingleFileUpload>
      <img v-else :src="getPictureUrl(passport.hold_passport_picture)" style="width: 10rem;height: 10rem;"
           @click="showPicture(passport.hold_passport_picture)">
      <template #extra>
        <a href="#" v-if="!hppEdit"><EditOutlined @click="edit(1)"/></a>
        <a href="#" v-else><UndoOutlined @click="cancel(1)"/></a>
      </template>
    </a-card>
  </a-space>
    <a-descriptions
        :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
        bordered
        layout="vertical"
        size="small"
        style="margin-top: 1rem;"
    >
      <a-descriptions-item label="Comment">{{ passport.comment }}</a-descriptions-item>
    </a-descriptions>
  </div>

  <a-space v-else style="margin: 1rem 0;">
    <a-button type="primary" @click="visible=true">Upload Passport</a-button>
  </a-space>

  <a-modal
      v-model:visible="visible"
      centered
      okText="Save"
      title="New Visa"
      @cancel="newReset"
      @ok="newSubmit"
  >
    <a-form ref="passportForm" :model="newItem" :scrollToFirstError="true">
      <a-space direction="vertical" style="width: 100%;">
        <SingleFileUpload text="Upload passport picture" @fileUploaded="passportUploaded"/>
        <SingleFileUpload text="Upload your picture holding passport" @fileUploaded="holdingPassportUploaded"/>
        <a-form-item name="comment" style="width: 100%;">
          <a-textarea v-model:value="newItem.comment" :auto-size="{ minRows: 5, maxRows: 10 }"
                      allow-clear placeholder="Comment" style="width: 100%;"/>
        </a-form-item>
      </a-space>
    </a-form>
  </a-modal>
</template>

<script>
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { createStaffPassportInfo, getStaffPassportInfo, updateStaffPassportInfo } from '../../api/staff'
import SingleFileUpload from '../common/SingleFileUpload'
import { message } from 'ant-design-vue'

import { EditOutlined, UndoOutlined } from '@ant-design/icons-vue'

export default {
  name: 'StaffPassport',
  components: { SingleFileUpload, EditOutlined, UndoOutlined },
  setup () {
    const route = useRoute()
    const store = useStore()
    const staffId = route.query.staffId || undefined
    const user = computed(() => store.state.auth.user)

    return {
      staffId,
      user
    }
  },

  created () {
    this.loadPassportInfo()
  },

  data () {
    return {
      passport: undefined,
      visible: false,

      ppEdit: false,
      hppEdit: false,

      newItem: {
        passportPicture: undefined,
        holdPassportPicture: undefined,
        comment: undefined,
        ppFileList: [],
        hppFileList: []
      }
    }
  },

  methods: {
    loadPassportInfo () {
      getStaffPassportInfo({
        staff_id: this.staffId
      }).then(res => {
        if (res.code === 0) {
          this.passport = res.data.passport
        }
      })
    },

    getPictureUrl (imgFile) {
      if (imgFile) {
        return '/img/passport/' + imgFile
      } else {
        return null
      }
    },

    showPicture (imgFile) {
      this.$preview(this.getPictureUrl(imgFile))
    },

    newReset () {
      this.$refs.passportForm.resetFields()
    },

    newSubmit () {
      if (!this.newItem.passportPicture || this.newItem.passportPicture.length === 0 ||
        !this.newItem.holdPassportPicture || this.newItem.holdPassportPicture.length === 0) {
        message.error('Please upload all the pictures first')
        return
      }

      const params = {
        staff_id: this.staffId,
        creator_id: this.user.id,
        passport_picture: this.newItem.passportPicture,
        hold_passport_picture: this.newItem.holdPassportPicture,
        comment: this.newItem.comment
      }

      this.$loading.show('Saving passport information...')

      createStaffPassportInfo(params).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.visible = false
          message.success('Successfully upload passport information')
          this.passport = res.data.passport
        }
      }).catch(err => {
        this.$loading.hide()
        console.log(err)
      })
    },

    passportUploaded (fileName, _) {
      this.newItem.passportPicture = fileName
      if (this.passport?.id) {
        updateStaffPassportInfo({
          id: this.passport.id,
          passport_picture: fileName,
          modifier_id: this.user.id
        }).then(res => {
          if (res.code === 0) {
            this.passport.passport_picture = fileName
            message.success('Passport picture has been updated')
            this.ppEdit = false
          }
        })
      }
    },

    holdingPassportUploaded (fileName, _) {
      this.newItem.holdPassportPicture = fileName
      if (this.passport?.id) {
        updateStaffPassportInfo({
          id: this.passport.id,
          hold_passport_picture: fileName,
          modifier_id: this.user.id
        }).then(res => {
          if (res.code === 0) {
            this.passport.hold_passport_picture = fileName
            message.success('Hold passport picture has been updated')
            this.hppEdit = false
          }
        })
      }
    },

    edit (index) {
      if (index === 0) {
        this.ppEdit = true
      } else {
        this.hppEdit = true
      }
    },

    cancel (index) {
      if (index === 0) {
        this.ppEdit = false
      } else {
        this.hppEdit = false
      }
    }
  }
}
</script>

<style scoped>

</style>
