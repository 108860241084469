<template>
  <div style="margin-top: 10rem;">Page will be available soon, please visit <router-link to="/">Home</router-link> </div>
</template>

<script>
export default {
  name: '404'
}
</script>

<style scoped>

</style>
