<template>
    <a-divider orientation="left">
        Visa list
    </a-divider>

    <a-table :columns="columns" :data-source="visas" :pagination="pager" rowKey="id" size="small"
             style="margin-top: 1rem;" @change="onChange">
        <template #index="{ index }">
            {{ pager.pageSize * (pager.current - 1) + index + 1 }}
        </template>
        <template #name="{ record }">
            <a :href="`/staff/staff_info?staffId=${record.staff_id}&page=visa`" target="_blank">{{ record.f_name + ' ' + record.l_name }}</a>
        </template>
        <template v-for="col in ['start', 'due']" :key="col" #[col]="{ text }">
            {{ showDate(text, false) }}
        </template>
        <template v-for="col in ['comment']" :key="col" #[col]="{ text }">
            <a-tooltip :title="text">
                <span>{{ text }}</span>
            </a-tooltip>
        </template>
        <template v-for="col in ['status']" :key="col" #[col]="{ record }">
          <a-tag :color="visaStatusColor[record.status]">
            {{ visaStatusCode[record.status] }}
          </a-tag>
        </template>
        <template v-for="col in ['days']" :key="col" #[col]="{ text }">
            <span v-if="text > 15">
                {{ text }}
            </span>
            <a-tag v-else color="red">
                {{ text }}
            </a-tag>
        </template>
    </a-table>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { getVisaList } from '../../api/staff'
import { message } from 'ant-design-vue'
import { showDate } from '../../utils/util'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: '25%',
    slots: { customRender: 'name' },
    ellipsis: true
  },
  {
    title: 'Type',
    dataIndex: 'visa_type',
    width: '10%',
    ellipsis: true
  },
  {
    title: 'Start',
    dataIndex: 'start',
    width: '10%',
    slots: { customRender: 'start' },
    sorter: (a, b) => a.due - b.due,
    ellipsis: true
  },
  {
    title: 'Due',
    dataIndex: 'due',
    width: '10%',
    slots: { customRender: 'due' },
    sorter: (a, b) => a.due - b.due
  },
  {
    title: 'Expire in',
    dataIndex: 'days',
    width: '10%',
    slots: { customRender: 'days' },
    sorter: (a, b) => a.days - b.days
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: '10%',
    slots: { customRender: 'status' },
    filters: [
      {
        text: 'Valid',
        value: '0'
      },
      {
        text: 'Expired',
        value: '1'
      }
    ]
    // ,
    // sortOrder: 'ascend'
  },
  {
    title: 'Comments',
    dataIndex: 'comment',
    width: '20%',
    slots: { customRender: 'comment' },
    ellipsis: true
  }
]

export default {
  name: 'VisaList',
  setup () {
    const store = useStore()
    const userId = computed(() => store.state.auth.user.id)

    return {
      userId
    }
  },

  mounted () {
    this.loadVisaList()
  },

  data () {
    return {
      visas: [],
      columns,
      pager: {
        pageSize: 20,
        total: 0,
        current: 1
      },
      filters: {},
      sorters: [],
      visaStatusCode: [
        'Valid',
        'Expired',
        'Suspended'
      ],
      visaStatusColor: [
        'green',
        'red',
        'yellow'
      ]
    }
  },

  methods: {
    loadVisaList () {
      const params = {
        pager: {
          ...this.pager,
          offset: (this.pager.current - 1) * this.pager.pageSize,
          limit: this.pager.pageSize
        },
        filters: this.filters,
        sorters: this.sorters
      }

      this.$loading.show('Loading visa list...')

      getVisaList(params).then(res => {
        if (res.code === 0) {
          this.visas = res.data.visas
          this.pager.total = res.data.total
        } else {
          message.error('Failed to load visa list, please retry')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$loading.hide()
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination

      this.filters = tableFilters

      this.sorters = tableSorters

      this.loadVisaList()
    },

    showDate
  }
}
</script>

<style scoped>

</style>
