import request from '@/utils/request'

const userApi = {
  Login: '/user/login',
  Register: '/user/register',
  ApplyStepOne: '/user/apply_one',
  ApplyStepTwo: '/user/apply_two',
  ChangePassword: '/user/change_pwd'
}

export function changePassword (parameter) {
  return request({
    url: userApi.ChangePassword,
    method: 'post',
    data: parameter
  })
}

export function applyOne (parameter) {
  return request({
    url: userApi.ApplyStepOne,
    method: 'post',
    data: parameter
  })
}

export function applyTwo (parameter) {
  return request({
    url: userApi.ApplyStepTwo,
    method: 'post',
    data: parameter
  })
}

export function login (parameter) {
  return request({
    url: userApi.Login,
    method: 'post',
    data: parameter
  })
}

export function registerUser (parameter) {
  return request({
    url: userApi.Register,
    method: 'post',
    data: parameter
  })
}
