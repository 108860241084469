import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/components/layout/HomeLayout'),
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/components/layout/HomeLayout'),
    redirect: '/dashboard/attendance',
    children: [
      {
        path: '/dashboard/attendance',
        name: 'attendance_stats',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/AttendanceV3'),
        meta: { requiresAuth: true }
      }
      // {
      //   path: '/dashboard/attendance',
      //   name: 'attendance_stats',
      //   component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/AttendanceV2'),
      //   meta: { requiresAuth: true }
      // }
      // {
      //   path: '/stock_list',
      //   name: 'products',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/product/StockList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/new_stock',
      //   name: 'newStock',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/product/NewStock'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/sku_list',
      //   name: 'skuList',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/product/SkuList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/new_sku',
      //   name: 'newSku',
      //   component: () => import(/* webpackChunkName: "home" */ '@/views/product/NewSku'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/settings',
      //   name: 'settings',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/user/Settings'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/orders',
      //   name: 'orders',
      //   component: () => import(/* webpackChunkName: "user" */ '@/views/order/OrderList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/category_list',
      //   name: 'category',
      //   component: () => import(/* webpackChunkName: "category" */ '@/views/category/CategoryList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/brand_list',
      //   name: 'brand',
      //   component: () => import(/* webpackChunkName: "category" */ '@/views/category/BrandList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/category_brand',
      //   name: 'categoryBrand',
      //   component: () => import(/* webpackChunkName: "category" */ '@/views/category/CategoryBrand'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/grade_list',
      //   name: 'grade',
      //   component: () => import(/* webpackChunkName: "category" */ '@/views/category/GradeList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/spu_list',
      //   name: 'spu',
      //   component: () => import(/* webpackChunkName: "spu" */ '@/views/spu/SpuList'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/spu_spec',
      //   name: 'spu_spec',
      //   component: () => import(/* webpackChunkName: "spu" */ '@/views/spu/SpuSpec'),
      //   meta: { requiresAuth: true }
      // },
      // {
      //   path: '/spec_list',
      //   name: 'spec_list',
      //   component: () => import(/* webpackChunkName: "spu" */ '@/views/spu/SpecList'),
      //   meta: { requiresAuth: true }
      // }
    ]
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import(/* webpackChunkName: "staff" */ '@/components/layout/HomeLayout'),
    redirect: '/staff/staff_list',
    children: [
      {
        path: '/staff/candidate_list',
        name: 'candidate_list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/CandidateList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/staff_list',
        name: 'staff_list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/StaffList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/staff_info',
        name: 'staff_info',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/StaffInfo'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/loan_list',
        name: 'loan_list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/LoanList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/visa_list',
        name: 'visa_list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/VisaList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/resign_list',
        name: 'resign_list',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/ResignList'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/user/Settings'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/attendance',
        name: 'personalAttendance',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/PersonalAttendance'),
        meta: { requiresAuth: true }
      },
      {
        path: '/staff/payroll',
        name: 'payroll',
        component: () => import(/* webpackChunkName: "staff" */ '@/views/staff/Payroll'),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: () => import(/* webpackChunkName: "user" */ '@/components/layout/UserLayout'),
    children: [
      {
        path: '/user/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: '/user/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Register')
      },
      {
        path: '/user/verify',
        name: 'verify',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/verify/Verify')
      },
      {
        path: '/user/verify_result',
        name: 'VerifyResult',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/verify/VerifyResult')
      },
      {
        path: '/user/apply',
        name: 'apply',
        component: () => import(/* webpackChunkName: "user" */ '@/views/user/Apply')
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]

export const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

// 路由控制，return true放行，return false禁止
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !store.state.auth.loggedIn) {
    return {
      path: '/user/login',
      query: { redirect: to.fullPath }
    }
  }
  return true
})

export default router
