<template>
    <a-divider orientation="left">
        Staff list
    </a-divider>

    <a-space>
        <a-input v-model:value="search" allowClear placeholder="Search in name" @change="searchChange" @keyup.enter="doSearch">
            <template #suffix>
                <SearchOutlined style="cursor: pointer;" @click="doSearch"/>
            </template>
        </a-input>
        <a-divider type="vertical" />
        <a-button type="primary" @click="newStaff">New Staff</a-button>
    </a-space>

    <a-table :columns="columns" :data-source="staffs" :pagination="pager" rowKey="id" size="small"
             style="margin-top: 1rem;" @change="onChange">
        <template #index="{ index }">
            {{ pager.pageSize * (pager.current - 1) + index + 1 }}
        </template>
        <template v-for="col in ['first_name', 'last_name']" :key="col" #[col]="{ text, record }">
            <div>
                <a-input
                        v-if="editableData[record.id]"
                        v-model:value="editableData[record.id][col]"
                        style="margin: -5px 0"
                />
                <template v-else>
                    <a :href="`/staff/staff_info?staffId=${record.id}`" target="_blank">{{ text }}</a>
                </template>
            </div>
        </template>
        <template v-for="col in ['referrer']" :key="col" #[col]="{ text, record }">
            <a :href="`/staff/staff_info?staffId=${record.referrer_id}`" target="_blank">{{ text }}</a>
        </template>
        <template v-for="col in ['comment', 'leave_comment']" :key="col" #[col]="{ text, record }">
            <div>
                <a-input
                        v-if="editableData[record.id]"
                        v-model:value="editableData[record.id][col]"
                        style="margin: -5px 0"
                />
                <template v-else>
                    {{ text }}
                </template>
            </div>
        </template>

        <template v-for="col in ['join_date', 'leave_date']" :key="col" #[col]="{ text, record }">
            <div>
                <a-date-picker
                        v-if="editableData[record.id]"
                        v-model:value="editableData[record.id][col]"
                        style="margin: -5px 0"
                />
                <template v-else>
                    {{ showDate(text, false) }}
                </template>
            </div>
        </template>
<!--        -->
<!--        <template #join_date="{ record }">-->
<!--            <a-date-picker-->
<!--                    v-if="editableData[record.id]"-->
<!--                    v-model:value="editableData[record.id].join_date"-->
<!--                    :allow-clear="false"-->
<!--                    style="margin: -5px 0; width: 100%"-->
<!--            />-->
<!--            <template v-else>-->
<!--                {{-->
<!--                showDate(record.join_date, false)-->
<!--                }}-->
<!--            </template>-->
<!--        </template>-->
<!--        <template #leave_date="{ record }">-->
<!--            <a-date-picker-->
<!--                    v-if="editableData[record.id]"-->
<!--                    v-model:value="editableData[record.id].leave_date"-->
<!--                    :allow-clear="false"-->
<!--                    style="margin: -5px 0; width: 100%"-->
<!--            />-->
<!--            <template v-else>-->
<!--                {{-->
<!--                showDate(record.leave_date, false)-->
<!--                }}-->
<!--            </template>-->
<!--        </template>-->
        <!--    <template #expandedRowRender="{ record }">-->
        <!--      <a-row :gutter="32" style="padding: 1rem 0">-->
        <!--        <a-col>-->
        <!--          More operations:-->
        <!--        </a-col>-->
        <!--        <a-col :xs="24" style="margin-top:1rem">-->
        <!--          <a @click="newLeave(record.id)">1. Create new leave record</a>-->
        <!--        </a-col>-->
        <!--        <a-col :xs="24" style="margin-top:1rem">-->
        <!--          <a @click="newBorrrow(record.id)">2. Create new loan record</a>-->
        <!--        </a-col>-->
        <!--        <a-col :xs="24" style="margin-top:1rem">-->
        <!--          <a @click="renewVisa(record.id)">3. Renew visa</a>-->
        <!--        </a-col>-->
        <!--      </a-row>-->
        <!--    </template>-->
        <template #operation="{ record }">
            <div class="editable-row-operations">
            <span v-if="editableData[record.id]">
              <a @click="save(record.id)">Save</a>
              <a style="margin-left: 1rem;" @click="cancel(record.id)">Cancel</a>
            </span>
                <span v-else>
          <a-space>
              <a-button @click="edit(record.id)">Edit</a-button>
          </a-space>
        </span>
            </div>
        </template>
    </a-table>
</template>

<script>

import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import { message } from 'ant-design-vue'
import { useStore } from 'vuex'
import { computed } from '@vue/reactivity'
import { getStaffList, updateStaffInfo } from '../../api/staff'
import { SearchOutlined } from '@ant-design/icons-vue'
import { getCountryList } from '../../api/reer'
import { showDate } from '../../utils/util'
import { StaffStatusCode } from '../../api/codes'

const columns = [
  {
    title: '#',
    dataIndex: 'index',
    slots: { customRender: 'index' },
    width: '5%'
  },
  {
    title: 'First Name',
    dataIndex: 'first_name',
    width: '10%',
    slots: { customRender: 'first_name' },
    ellipsis: true
  },
  {
    title: 'Last Name',
    dataIndex: 'last_name',
    width: '10%',
    slots: { customRender: 'last_name' },
    ellipsis: true
  },
  {
    title: 'Referrer',
    dataIndex: 'referrer',
    width: '10%',
    slots: { customRender: 'referrer' },
    ellipsis: true
  },
  {
    title: 'Comment',
    dataIndex: 'comment',
    width: '20%',
    slots: { customRender: 'comment' },
    ellipsis: true
  },
  {
    title: 'Join',
    dataIndex: 'join_date',
    width: '10%',
    slots: { customRender: 'join_date' },
    sorter: (a, b) => a.join_date - b.join_date,
    ellipsis: true
  },
  {
    title: 'Resign',
    dataIndex: 'leave_date',
    width: '10%',
    slots: { customRender: 'leave_date' },
    sorter: (a, b) => a.leave_date - b.leave_date,
    ellipsis: true
  },
  {
    title: 'Resign Comment',
    dataIndex: 'leave_comment',
    width: '20%',
    slots: { customRender: 'leave_comment' },
    ellipsis: true
  },
  {
    title: 'OP',
    dataIndex: 'operation',
    slots: { customRender: 'operation' },
    width: '15%'
  }
]

export default {
  name: 'ResignList',

  components: {
    SearchOutlined
  },

  setup () {
    const store = useStore()

    const userId = computed(() => store.state.auth.user.id)
    const staffLoadingParams = computed(() => store.state.transition.staffLoadingParams)

    const saveCountriesInCache = ({ countries }) => store.dispatch('reer/cacheCountries', { countries })
    const cacheCountries = computed(() => store.state.reer.countries)

    const cacheStaffLoadingInfo = ({
      pager,
      filters,
      sorters,
      search
    }) => store.dispatch('transition/cacheStaffLoadingInfo', {
      pager,
      filters,
      sorters,
      search
    })

    return {
      userId,
      staffLoadingParams,
      cacheStaffLoadingInfo,
      saveCountriesInCache,
      cacheCountries
    }
  },

  mounted () {
    this.loadStaffList()
    this.loadCountries()
  },

  watch: {
    search (val) {
      this.searchTerms = {
        name: val
      }
    }
  },

  data () {
    return {
      columns,
      editableData: {},
      staffs: [],
      totalStaffs: [], // 仅包含id，first_name和last_name的所有员工信息，便于添加referrerId
      searchTerms: this.staffLoadingParams?.search || {},
      pager: this.staffLoadingParams?.pager || {
        pageSize: 10,
        total: 0,
        current: 1
      },
      filters: this.staffLoadingParams?.filters || {},
      sorters: this.staffLoadingParams?.sorters || [],
      countries: this.cacheCountries || [],
      search: this.searchTerms?.name || ''
    }
  },

  methods: {
    loadStaffList () {
      const params = {
        pager: {
          ...this.pager,
          offset: (this.pager.current - 1) * this.pager.pageSize,
          limit: this.pager.pageSize
        },
        filters: { status: StaffStatusCode.RESIGN, ...this.filters },
        sorters: this.sorters,
        search: this.searchTerms
      }

      this.$loading.show('Loading staff list...')

      getStaffList(params).then(res => {
        if (res.code === 0) {
          this.cacheStaffLoadingInfo(params)
          this.staffs = res.data.staffs
          this.pager.total = res.data.total
          if (res.data.total_staffs && res.data.total_staffs.length > 0) {
            this.totalStaffs = res.data.total_staffs
          }
          this.totalStaffs.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
        } else {
          message.error('Failed to load staff list, please retry')
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.$loading.hide()
      })
    },

    loadCountries () {
      this.fetchingCountries = true
      getCountryList().then(res => {
        if (res.code === 0) {
          this.countries = res.data.countries
          this.phoneCodes = res.data.countries ? res.data.countries.slice() : []
          this.phoneCodes.sort((a, b) => {
            if (a.phone_code < b.phone_code) {
              return -1
            }
            if (a.phone_code > b.phone_code) {
              return 1
            }
            return 0
          })
          this.saveCountriesInCache({ countries: this.countries })
        }
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.fetchingCountries = false
      })
    },

    onChange (pagination, tableFilters, tableSorters) {
      this.pager = pagination

      console.log('Remember transform table filters to filters')
      this.filters = {}

      console.log(tableSorters)

      this.sorters = tableSorters

      this.loadStaffList()
    },

    edit (key) {
      this.editableData[key] = cloneDeep(this.staffs.filter(item => item.id === key)[0])
    },

    cancel (key) {
      delete this.editableData[key]
    },

    save (key) {
      const params = {
        id: key,
        first_name: this.editableData[key].first_name,
        last_name: this.editableData[key].last_name,
        comment: this.editableData[key].comment,
        join_date: this.editableData[key].join_date,
        leave_date: this.editableData[key].leave_date,
        leave_comment: this.editableData[key].leave_comment
      }

      updateStaffInfo(params).then(res => {
        if (res.code === 0) {
          this.loadStaffList()
        } else {
          message.error('Failed to update staff info, please retry')
        }
        delete this.editableData[key]
      }).catch(err => {
        console.log(err)
      })
    },

    getPictureUrl (imgFile) {
      return '/img/passport/' + imgFile
    },

    showPicture (imgFile) {
      this.$preview(this.getPictureUrl(imgFile))
    },

    showDiffDays (date) {
      const diffDays = moment().diff(date, 'days', false)
      if (diffDays === 0) {
        return 'Today'
      } else if (diffDays === 1) {
        return 'Yesterday'
      } else {
        return `${diffDays} days ago`
      }
    },

    doSearch () {
      this.loadStaffList()
    },

    searchChange (e) {
      if (e.type === 'click') {
        this.searchTerms = {}
        this.loadStaffList()
      }
    },

    showDate
  }
}
</script>

<style scoped>

.title {
    font-weight: bold;
    color: #aaa;
    width: 15rem;
}

</style>
