<template>
  <div class="main" style="padding: 1rem; text-align: left">
    <div style="margin-top: 3rem; font-size: 1.2rem; font-weight: bolder; text-align: center">Account has been
      created!
    </div>

    <div style="margin-top: 3rem;">A verification message has been sent to
      <span class="email"><a :href="toHref()" target="_blank">{{ email }}</a></span>,
      please check your email and follow the message to complete your registration.
    </div>

    <div style="margin-top: 3rem;">
      <Alert banner message="Don't forget to check your junk email."/>
    </div>

  </div>
</template>

<script>

import { getHostFromEmail } from '@/utils/util'

import { Alert } from 'ant-design-vue'

export default {
  name: 'VerifyRegistration',

  components: {
    Alert
  },

  data () {
    return {
      email: this.$route.params.email
    }
  },

  methods: {
    toHref () {
      return getHostFromEmail(this.email)
    }
  }
}
</script>

<style scoped>

.email {
  font-weight: bold;
  color: #1890ff;
}

</style>
