import request from '@/utils/request'

const userApi = {
  GetCountryList: '/user/countries',
  GetCityList: '/user/cities'
}

export function getCountryList (parameter) {
  return request({
    url: userApi.GetCountryList,
    method: 'post',
    data: parameter
  })
}

export function getCityList (parameter) {
  return request({
    url: userApi.GetCityList,
    method: 'post',
    data: parameter
  })
}
