<template>
  <div>
      <a-divider orientation="left">
          Attendance of <span style="color:red; margin-left: 1rem;">{{ showDate(currentDate, false) }} {{ currentDate.format('ddd')}}. </span>
      </a-divider>
      <a-space direction="horizontal">
          <a-button @click="prev">Last Day</a-button>
          <a-date-picker :allow-clear="false" placeholder="Pick one date" v-model:value="currentDate" @change="onChange">
          </a-date-picker>
          <a-button @click="next">Next Day</a-button>
      </a-space>
      <div style="height: 2rem;">
      </div>
      <a-row :gutter="[32, 32]">
          <a-col :xs="24" :md="12" :lg="8">
              <div class="list-title">Present List ({{ atts?.length }})</div>
              <a-list item-layout="horizontal" :data-source="atts">
                  <template #renderItem="{ item }">
                      <a-list-item>
                          <a-list-item-meta
                                  :description="`${ showTime(item.punch_time) } - ${item.device}`"
                          >
                              <template #title>
                                  <a :href="`/staff/staff_info?staffId=${item.id}`" target="_blank"> {{ `${item.card_id} - ${item.first_name} ${item.last_name}` }}</a>
                              </template>
                          </a-list-item-meta>
                      </a-list-item>
                  </template>
              </a-list>
          </a-col>
          <a-col :xs="24" :md="12" :lg="8">
              <div class="list-title">Absent List ({{ absents?.length }})</div>
              <a-list item-layout="horizontal" :data-source="absents">
                  <template #renderItem="{ item }">
                      <a-list-item>
                          <a-list-item-meta
                                  :description="`ID: ${item.card_id}`"
                          >
                              <template #title>
                                  <a :href="`/staff/staff_info?staffId=${item.id}`" target="_blank"> {{ `${item.card_id} - ${item.first_name} ${item.last_name}` }}</a>
                              </template>
<!--                              <template #avatar>-->
<!--                                  <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />-->
<!--                              </template>-->
                          </a-list-item-meta>
                      </a-list-item>
                  </template>
              </a-list>
          </a-col>
          <a-col :xs="24" :md="12" :lg="8">
              <div class="list-title">Leave List ({{ leaves?.length }}) </div>
              <a-list item-layout="horizontal" :data-source="leaves">
                  <template #renderItem="{ item }">
                      <a-list-item>
                          <a-list-item-meta
                                  :description="`${item.type}` + `${item.reason ? ' - ' + item.reason : ''}` + `${item.comment ? ' - ' + item.comment : ''}`"
                          >
                              <template #title>
                                  <a :href="`/staff/staff_info?staffId=${item.id}`" target="_blank"> {{ `${item.card_id} - ${item.first_name} ${item.last_name}` }}</a>
                              </template>
<!--                              <template #avatar>-->
<!--                                  <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />-->
<!--                              </template>-->
                          </a-list-item-meta>
                      </a-list-item>
                  </template>
              </a-list>
          </a-col>
      </a-row>
  </div>
  <div>
  </div>
</template>

<script>
import { showDate, showTime } from '../../utils/util'
import moment from 'moment'
import { getAttendanceExceptions } from '../../api/staff'
import { message } from 'ant-design-vue'

export default {
  name: 'AttendanceV3',

  data () {
    return {
      currentDate: moment(),
      absents: [],
      atts: [],
      leaves: []
    }
  },

  created () {
    this.loadExceptionList()
  },

  methods: {
    loadExceptionList () {
      this.$loading.show('Loading attendance info...')
      getAttendanceExceptions({
        date: this.currentDate.format('YYYY-MM-DD')
      }).then(res => {
        this.$loading.hide()
        if (res.code === 0) {
          this.absents = res.data.absents
          this.atts = res.data.atts
          this.leaves = res.data.leaves
        } else {
          message.error('Failed to load attendance info, please retry later')
        }
      }).catch(err => {
        console.log(err)
        this.$loading.hide()
      })
    },

    prev () {
      const dt = this.currentDate.subtract(1, 'days')
      this.currentDate = moment(dt)
      this.loadExceptionList()
    },

    next () {
      const dt = this.currentDate.add(1, 'days')
      this.currentDate = moment(dt)
      this.loadExceptionList()
    },

    onChange (_) {
      this.loadExceptionList()
    },

    showDate,
    showTime
  }
}
</script>

<style scoped>

.list-title {
    font-size: 1.2rem;
    font-weight: bold;
    border-bottom: 1px solid #aaa;
}

</style>
