<template>
    <a-row>
        <a-col :xs="4">
            <div class="staff-list">
                <div v-for="staff in staffs"
                     v-bind:key="staff.id"
                     :class="{'active' : staffId === staff.id}" class="staff"
                     @click="showPayroll(staff.id)">
                    {{ staff.card_id }}-{{ staff.first_name }} {{ staff.last_name }}
                </div>
            </div>
        </a-col>
        <a-col :xs="20" style="padding-left: 1rem;">
            <a-divider orientation="left">
                Basic Info
            </a-divider>
            <a-descriptions
                    v-if="staffId"
                    bordered
                    size="small"
            >
                <a-descriptions-item label="Name">{{ staff.first_name }} {{ staff.last_name }}</a-descriptions-item>
                <a-descriptions-item label="Birthday">{{ showDate(staff.birthday, false) }}
                    ({{ showDate(staff.birthday, true) }})
                </a-descriptions-item>
                <a-descriptions-item label="Join Date">{{ showDate(staff.join_date, false) }}</a-descriptions-item>
                <a-descriptions-item label="Nationality">{{ staff.country }}</a-descriptions-item>
                <a-descriptions-item label="Referrer">{{ staff.referrer }}</a-descriptions-item>
                <a-descriptions-item label="Salary">{{ staff.salary }} {{ staff.currency }}</a-descriptions-item>
            </a-descriptions>
            <a-divider orientation="left">
                Salary Updates
            </a-divider>
            <StaffSalary v-model:staffId="staffId"></StaffSalary>
            <a-divider orientation="left">
                Payroll Records
            </a-divider>
            <StaffPayroll v-model:staffId="staffId"></StaffPayroll>
            <a-divider orientation="left">
                Leave Records
            </a-divider>
            <StaffLeaveNoEditor v-model:staffId="staffId"></StaffLeaveNoEditor>
        </a-col>
    </a-row>
</template>

<script>

import { useRoute } from 'vue-router'
import { getStaffListWithSalary } from '../../api/staff'
import { message } from 'ant-design-vue'
import { ref } from 'vue'
import { showDate } from '../../utils/util'
import StaffSalary from '../../components/staff/StaffSalary'
import StaffPayroll from '../../components/staff/StaffPayroll'
import StaffLeaveNoEditor from '../../components/staff/StaffLeaveNoEditor'

export default {
  name: 'Payroll.vue',
  components: { StaffLeaveNoEditor, StaffPayroll, StaffSalary },
  setup () {
    const route = useRoute()
    const staffId = ref(route.query.staffId)

    return {
      staffId
    }
  },

  created () {
    this.loadStaffList()
  },

  data () {
    return {
      staff: {},
      staffs: [],
      totalStaffs: []
    }
  },

  methods: {
    loadStaffList () {
      const params = {}

      this.$loading.show('Loading staff list...')

      getStaffListWithSalary(params).then(res => {
        if (res.code === 0) {
          this.staffs = res.data.staffs
          if (res.data.total_staffs && res.data.total_staffs.length > 0) {
            this.totalStaffs = res.data.total_staffs
          }
          this.totalStaffs.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
        } else {
          message.error('Failed to load staff list, please retry')
        }
      }).catch(err => {
        console.log(err)
        message.error('Failed to load staff list, please retry')
      }).finally(() => {
        this.$loading.hide()
      })
    },

    showPayroll (id) {
      this.staffId = id
      this.staff = this.staffs.filter(item => item.id === id)[0]
    },

    showDate
  }
}
</script>

<style lang="scss" scoped>

.staff-list {
  border-right: 1px solid #f1f1f1;
  padding-right: 1rem;

  .staff {
    padding: 0.2rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;

    &.active {
      background: #d46b08;
      color: white;
      font-weight: bold;
    }
  }
}

</style>
